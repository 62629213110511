<div class="modal-header">
  <h5 class="modal-title" id="modifyModalLabel">Mitarbeiter</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label>Zugeordnete Mitarbeiter</label>
  <div class="table mt-2 table-editable" id="dataTable" role="grid" aria-describedby="dataTable_info">
    <table class="table my-0 table-condensed table-responsive-xl" id="dataTable">
      <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Kapazität</th>
        <th scope="col">Aktionen</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let employee of employees">
        <td>
          <span>{{employee.name}}</span>
        </td>
        <td>
          <span>{{employee.capacity}}</span>
        </td>
        <td>
          <a (click)="openShowMeasures(employee)">
            <i class="material-icons">remove_red_eye</i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div><br>
</div>
<div class="modal-footer">
  <div id="submit" style="padding: 40px 20px;margin: 0px;">
    <!-- <button class="btn btn-lg btn-primary btn-block" id="submitBtn" type="submit" data-dismiss="modal">Speichern</button> -->
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>
