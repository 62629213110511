import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../Services/auth/auth.service';
import {Router} from '@angular/router';
import {Role} from '../../Interfaces/role';
import {TeamleaderService} from '../../Services/teamleader/teamleader.service';
import {EmployeeService} from '../../Services/employee/employee.service';
import {DeleteModalComponent} from '../Models/delete-modal/delete-modal.component';
import {Teamleader} from '../../Interfaces/teamleader';
import {AddTeamleaderModalComponent} from '../Models/add-teamleader-modal/add-teamleader-modal.component';
import {ShowTeamleaderEmployeeModalComponent} from '../Models/show-teamleader-employee-modal/show-teamleader-employee-modal.component';
import {EditTeamleaderModalComponent} from '../Models/edit-teamleader-modal/edit-teamleader-modal.component';

@Component({
  selector: 'app-team-leader',
  templateUrl: './team-leader.component.html',
  styleUrls: ['./team-leader.component.css']
})
export class TeamLeaderComponent implements OnInit {
  // Pagination
  page = 1;
  pageSize = 10;
  collectionSize = 0;

  // Employee Data
  teamleaderArray: Teamleader[] = [];
  teamleaderArrayTmp: Teamleader[] = [];
  teamleaderData: Teamleader[] = [];
  selectedTeamleader: Teamleader;

  // Search
  searchTerm = '';
  teamleaderDataSearch = new BehaviorSubject<boolean>(false);

  // Special Access
  canEdit = new BehaviorSubject<boolean>(false);

  constructor(private modalService: NgbModal, private authService: AuthService,
              private teamleaderService: TeamleaderService, private employeeService: EmployeeService,
              private router: Router) {
    this.checkRole();
    this.getTeamleaderList();
  }

  ngOnInit(): void {
  }

  openAddTeamleaderModal(): void {
    const modalRef = this.modalService.open(AddTeamleaderModalComponent, {size: 's', centered: true});
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          this.router.navigate(['employee']).then(() => {
            window.location.reload();
          });
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openShowEmployees(teamleader: Teamleader): void {
    const modalRef = this.modalService.open(ShowTeamleaderEmployeeModalComponent, {size: 'xl', centered: true});
    modalRef.componentInstance.teamleader = teamleader;
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openEditTeamleaderModal(teamleader: Teamleader): void {
    const modalRef = this.modalService.open(EditTeamleaderModalComponent, {size: 's', centered: true});
    modalRef.componentInstance.teamleader = teamleader;
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          this.router.navigate(['employee']).then(() => {
            window.location.reload();
          });
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openDeleteModal(teamleader: Teamleader): void {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 's', centered: true});
    modalRef.result.then(
      result => {
        if (result === 'Delete') {
          this.teamleaderService.deleteTeamleader(teamleader).subscribe(_ => {
            this.router.navigate(['employee']).then(() => {
              window.location.reload();
            });
          });
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  // Pagination
  public paginationRefresh(): void {
    this.teamleaderData = this.teamleaderArray.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  public getTeamleaderList(): void {
    this.teamleaderService.getTeamleaders().subscribe(
      requestsData => {
        this.teamleaderArray = requestsData['results'];

        this.teamleaderArray.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        // Store the same into tmp
        this.teamleaderArrayTmp = this.teamleaderArray;

        for (const teamleader of this.teamleaderArray) {
          this.getEmployeeList(teamleader);
        }

        this.collectionSize = this.teamleaderArray.length;
        this.teamleaderData = this.teamleaderArray.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      });
  }

  // Employee
  getEmployeeList(teamleader): void {
    const teamleaderName = teamleader.name;

    this.employeeService.getEmployeesForTeamleaderWithName(teamleaderName).subscribe(employees => {
      teamleader.employees = employees;
    });
  }

  // Search Action
  searchTermChanged(): void {
    this.teamleaderArray = this.teamleaderArrayTmp;
    this.teamleaderArray = this.teamleaderArray.filter(word => word.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    this.collectionSize = this.teamleaderArray.length;
    this.paginationRefresh();
  }

  // Access
  checkRole(): void {
    this.authService.checkPermission([Role.Superadmin]).subscribe(
      resp => {
        this.canEdit.next(resp['result']);
      }
    );
  }
}
