import {Component, Input, OnInit} from '@angular/core';
import {Employee} from '../../../Interfaces/employee';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, of} from 'rxjs';
import {EmployeeHour} from '../../../Interfaces/employee-hour';
import {EmployeeService} from '../../../Services/employee/employee.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-show-employee-hours-modal',
  templateUrl: './show-employee-hours-modal.component.html',
  styleUrls: ['./show-employee-hours-modal.component.css']
})
export class ShowEmployeeHoursModalComponent implements OnInit {
  @Input() employee: Employee;
  employeeHours: Observable<EmployeeHour[]> = new Observable<EmployeeHour[]>();

  // Total hours
  totalHours = 0;

  constructor(public activeModal: NgbActiveModal, private employeeService: EmployeeService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getEmployeeHours(this.employee);
  }

  getEmployeeHours(employee: Employee): void {
    this.employeeService.getEmployeeHours(employee).subscribe(employeeHours => {
      const newEmployeeHoursArray = [];
      for (const employeeHour of employeeHours) {
        employeeHour.from = this.datePipe.transform(employeeHour.from['iso'], 'dd.MM.yyyy');
        employeeHour.to = this.datePipe.transform(employeeHour.to['iso'], 'dd.MM.yyyy');
        this.totalHours += +employeeHour.hours;
      }
      this.employeeHours = of(employeeHours);
    });
  }
}
