<div class="container-fluid">
  <div class="card shadow">
    <div class="card-header py-3">
      <div class="row">
        <div class="col-md-6">
          <div class="text-md-left">
            <p class="text-dark m-0 font-weight-bold">Fälle</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="text-md-center">
            <label>Laufende Maßnahmen: {{inProgress}}</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="text-md-right">
            <a (click)="openAddCaseModal()">
              <i class="material-icons">add_circle</i>
            </a>
            <a data-toggle="modal" data-target="#filterModal">
              <i class="material-icons">filter_alt</i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="text-md-left dataTables_filter" id="dataTable_filter">
            <label><input type="search" class="form-control form-control-sm" aria-controls="dataTable" [(ngModel)]="searchTerm" (ngModelChange)="searchTermChanged()" placeholder="Suchen"></label>
          </div>
        </div>
        <ng-container *ngIf="canEdit | async">
          <div class="col-md-2">
            <div class="text-md-center">
              <input type="file" name="import" accept="text/csv" (change)="importList($event)">
            </div>
          </div>
        </ng-container>
        <div class="col-md-6">
          <div class="text-md-right">
            <i class="material-icons" (click)="exportList()">cloud_download</i>
          </div>
        </div>
      </div>
      <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
        <table class="table my-0 table-condensed table-sm" id="dataTable">
          <thead>
            <tr>
              <th scope="col" [appSort]="CASESARRAY" [caseComponent]="this" data-order="desc" data-name="case">Fall</th>
              <th scope="col" [appSort]="CASESARRAY" [caseComponent]="this" data-order="desc" data-name="type">Maßnahmeart</th>
              <th scope="col" [appSort]="CASESARRAY" [caseComponent]="this" data-order="desc" data-name="report">Bericht abgegeben</th>
              <th scope="col" [appSort]="CASESARRAY" [caseComponent]="this" data-order="desc" data-name="inProgress">Läuft</th>
              <th  *ngIf="canEdit" scope="col" [appSort]="CASESARRAY" [caseComponent]="this" data-order="desc" data-name="kosi">Kosi</th>
              <th scope="col">Aktionen</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let case0 of caseData | async">
              <td>
                {{case0.case}}
              </td>
              <td>
                {{case0.type}}
              </td>
              <td>
                {{case0.report}}
              </td>
              <td>
                {{case0.inProgress}}
              </td>
              <td *ngIf="canEdit">
                {{case0.kosi}}
              </td>
              <td>
                <a (click)="onEdit(case0)">
                  <i class="material-icons">remove_red_eye</i>
                </a>
                <a (click)="openEditCaseModal(case0)">
                  <i class="material-icons">mode_edit</i>
                </a>
                <a (click)="openDeleteModal(case0)">
                  <i class="material-icons">delete</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Pagination -->
        <div class="d-flex justify-content-between p-2">
          <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="paginationRefresh()"></ngb-pagination>

          <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="paginationRefresh()">
            <option [ngValue]="100">100 Einträge pro Seite</option>
            <option [ngValue]="300">300 Einträge pro Seite</option>
            <option [ngValue]="500">500 Einträge pro Seite</option>
          </select>
        </div>
        <!-- End pagination -->
      </div>
    </div>
  </div>
</div>

<!-- FilterModal -->
<form [formGroup]="filterForm" (ngSubmit)="onFilter()" class="form-text">
  <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="addReportLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="filterModal">Liste filtern</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card mb-3">
            <div class="card-header">
              <h6>Bericht</h6>
            </div>
            <div class="card-body">
              <div class="row px-4 mb-2">
                <div class="col-12 form-check form-check-inline">
                  <select class="form-control" [(ngModel)]="selectedValueReport" formControlName="selectReport">
                    <option [value]="1" selected>Alle</option>
                    <option [value]="2">Abgegeben</option>
                    <option [value]="3">Nicht abgegeben</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3">
            <div class="card-header">
              <h6>Läuft</h6>
            </div>
            <div class="card-body">
              <div class="row px-4 mb-2">
                <div class="col-12 form-check form-check-inline">
                  <select class="form-control" [(ngModel)]="selectedValueProgress" formControlName="selectProgress">
                    <option [value]="1" selected>Alle</option>
                    <option [value]="2">Ja</option>
                    <option [value]="3">Nein</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3">
            <div class="card-header">
              <h6>Kosi</h6>
            </div>
            <div class="card-body">
              <div class="row px-4 mb-2">
                <div class="col-12 form-check form-check-inline">
                  <select class="form-control" [(ngModel)]="selectedValueKosi" formControlName="selectKosi">
                    <option [value]="1">Alle</option>
                    <option [value]="2">Ja</option>
                    <option [value]="3">Nein</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3">
            <div class="card-header">
              <h6>Teamleiter</h6>
            </div>
            <div class="card-body">
              <div class="row px-4 mb-2">
                <div class="col-12 form-check form-check-inline">
                  <select class="form-control" formControlName="selectTeamleader">
                    <option selected [value]="0">Alle</option>
                    <ng-container *ngFor="let teamLeader of selectTeamLeader">
                      <option [value]="teamLeader.name">{{teamLeader.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-lg btn-success btn-block" type="submit">Anwenden</button>
          <button class="btn btn-lg btn-danger btn-block" type="button" data-dismiss="modal">Schließen</button>
        </div>
      </div>
    </div>
  </div>
</form>
