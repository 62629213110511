<div class="modal-header">
  <h5 class="modal-title" id="modifyModalLabel">Maßnahmen</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="table mt-2 table-editable" id="dataTable" role="grid" aria-describedby="dataTable_info">
    <table class="table my-0 table-condensed table-responsive-xl" id="dataTable">
      <thead>
      <tr>
        <th scope="col">Fall</th>
        <th scope="col">Typ</th>
        <th scope="col">Von</th>
        <th scope="col">Bis</th>
        <th scope="col">STD.</th>
        <th scope="col">Mitarbeiter</th>
        <th scope="col">CM</th>
        <th scope="col">SZ</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let measure of measures | async; index as i">
        <td>
          <span>{{measure.case}}</span>
        </td>
        <td>
          <span>{{measure.type}}</span>
        </td>
        <td>
          <span>{{measure.from}}</span>
        </td>
        <td>
          <span>{{measure.to}}</span>
        </td>
        <td>
          <ng-container *ngFor="let employee of measure.employee">
            <span>{{employee[1]}}</span><br>
          </ng-container>
          <!-- <span>{{measure.employee[0][1]}}</span> -->
        </td>
        <td>
          <ng-container *ngFor="let employee of measure.employee">
            <span>{{employee[0]}}</span><br>
          </ng-container>
          <!-- <span>{{measure.employee[0][0]}}</span> -->
        </td>
        <td>
          <span>{{measure.caseManager}}</span>
        </td>
        <td>
          <span>{{measure.socialCentrum}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <div id="submit" style="padding: 40px 20px;margin: 0px;">
    <!-- <button class="btn btn-lg btn-primary btn-block" id="submitBtn" type="submit" data-dismiss="modal">Speichern</button> -->
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Schließen</button>
  </div>
</div>
