import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '../../../Services/employee/employee.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Teamleader} from '../../../Interfaces/teamleader';

@Component({
  selector: 'app-add-employee-modal',
  templateUrl: './add-employee-modal.component.html',
  styleUrls: ['./add-employee-modal.component.css']
})
export class AddEmployeeModalComponent implements OnInit {
  @Input() public teamLeaderSelectList: Teamleader[];

  // Error
  failingMessage = '';
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

  // Form
  addFormGroup: FormGroup;
  private formSubmitAttempt: boolean;

  arrayThree(n: number, startFrom: number): number[] {
    const allowedNumbers: number[] = [];
    for (let i = 5; i <= 48; i += 0.5) {
      allowedNumbers.push(i);
    }
    return allowedNumbers;
  }

  constructor(public activeModal: NgbActiveModal, private employeeService: EmployeeService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.addFormGroup = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^([^0-9]*)$')]],
      lastName: ['', [Validators.required, Validators.pattern('^([^0-9]*)$')]],
      hours: ['', [Validators.required]],
      teamLeader: [''],
      comment: ['']
    });
  }

  onSubmit(): void {
    this.employeeService.addEmployee(this.addFormGroup).subscribe(
      result => {
        if (Object.keys(result).length !== 0) {
          this.activeModal.close('Saved');
        } else {
          this.failingMessage = 'Eintrag ist bereits vorhanden';
        }
      });
  }

  // Form
  isFieldInvalid(field: string): boolean {
    return (
      (!this.addFormGroup.get(field).valid && this.addFormGroup.get(field).touched) ||
      (this.addFormGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
