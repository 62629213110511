import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Employee} from '../../Interfaces/employee';
import {AppSettings} from '../serviceConfig';
import {HttpClient} from '@angular/common/http';
import {FormGroup} from '@angular/forms';
import {EmployeeHour} from '../../Interfaces/employee-hour';
import {Teamleader} from '../../Interfaces/teamleader';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private api = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient) { }

  getEmployees(): Observable<Employee[]> {
    const endpoint = this.api + '/classes/Employee?limit=5000';

    return this.http.get<Employee[]>(endpoint).pipe(
      tap(res =>
        console.log('Recieved employees')),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: Employee[] = [];
        return of(empty);
      })
    );
  }

  getEmployeeHours(employee: Employee): Observable<EmployeeHour[]> {
    const endpoint = this.api + '/classes/EmployeeMonthHours?limit=5000';

    return this.http.get<EmployeeHour[]>(endpoint).pipe(
      tap(_ => console.log('Recieved employees')),
      map(employeeHours => {
        const employeeHoursArray = employeeHours['results'];

        const returnArray: any[] = [];
        for (const employeeHourArray of employeeHoursArray) {
          if (employeeHourArray.name === employee.name) {
            returnArray.push(employeeHourArray);
          }
        }
        return returnArray;
      })
    );
  }

  getEmployeesForTeamleaderWithName(teamleader: Teamleader): Observable<Employee[]> {
    const endpoint = this.api + '/classes/Employee?limit=10000';

    return this.http.get<Employee[]>(endpoint).pipe(
      tap(_ => console.log('Recieved employees')),
      map(employees => {
        const employeesArray = employees['results'];

        const returnArray: Employee[] = [];
        for (const employee of employeesArray) {
          for (const employeesName of employee.teamLeader) {
            if (employeesName === teamleader.name) {
              returnArray.push(employee);
            }
          }
        }

        return returnArray;
      })
    );
  }

  addEmployee(form: FormGroup): Observable<{}> {
    const endpoint = this.api + '/classes/Employee';

    const fullName = form.value.firstName + ' ' + form.value.lastName;

    return this.http.post<{}>(endpoint, {name: fullName, teamLeader: [form.value.teamLeader],
      hours: form.value.hours, comment: form.value.comment}).pipe(
      tap(res =>
        console.log('Added employee ' + res['result'])),
      catchError(error => {
        console.log(error.error.error);
        const empty: {} = {};
        return of(empty);
      })
    );
  }

  updateEmployee(employee: Employee, entries): Observable<JSON> {
    const endpoint = this.api + '/classes/Employee/' + employee.objectId;

    const body = {};
    for (const entry of entries) {
      Object.assign(body, entry);
    }

    return this.http.put<JSON>(endpoint, body).pipe(
      tap(res =>
        console.log('Updated at: '))
    );
  }

  deleteEmployee(employee: Employee): Observable<{}> {
    const endpoint = this.api + '/classes/Employee/' + employee.objectId;

    return this.http.delete<JSON>(endpoint).pipe(
      tap(res =>
        console.log('Deleted at: ' + res))
    );
  }
}

