import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Teamleader} from '../../../Interfaces/teamleader';
import {EmployeeService} from '../../../Services/employee/employee.service';
import {Employee} from '../../../Interfaces/employee';
import {ShowMeasureEmployeeModalComponent} from '../show-measure-employee-modal/show-measure-employee-modal.component';

@Component({
  selector: 'app-show-teamleader-employee-modal',
  templateUrl: './show-teamleader-employee-modal.component.html',
  styleUrls: ['./show-teamleader-employee-modal.component.css']
})
export class ShowTeamleaderEmployeeModalComponent implements OnInit {
  @Input() teamleader: Teamleader;
  employees: Employee[];

  // Collapse
  isCollapsed = true;

  constructor(public activeModal: NgbActiveModal, private employeeService: EmployeeService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.employeeService.getEmployeesForTeamleaderWithName(this.teamleader).subscribe(result => {
      result.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
      });

      this.employees = result;
    });
  }

  openShowMeasures(employeeData: Employee): void {
    const modalRef = this.modalService.open(ShowMeasureEmployeeModalComponent, {size: 'xl', centered: true, scrollable: true});
    modalRef.componentInstance.employee = employeeData;
    // modalRef.componentInstance.measures = of(employeeData.measures);
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          // this.getMeasureList(this.selectedCase.case);
        }
      },
      reason => {
        console.log('Closed');
      });
  }

}
