import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Teamleader} from '../../../Interfaces/teamleader';
import {TeamleaderService} from '../../../Services/teamleader/teamleader.service';

@Component({
  selector: 'app-edit-teamleader-modal',
  templateUrl: './edit-teamleader-modal.component.html',
  styleUrls: ['./edit-teamleader-modal.component.css']
})
export class EditTeamleaderModalComponent implements OnInit {
  @Input() teamleader: Teamleader;

  // Form
  editGroup: FormGroup;
  private formSubmitAttempt: boolean;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,
              private teamleaderService: TeamleaderService) { }

  ngOnInit(): void {
    const splittedCase = this.teamleader.name.split(' ');
    const firstName = splittedCase[0];
    const lastName = splittedCase[1];

    this.editGroup = this.fb.group({
      firstName: [firstName, [Validators.required, Validators.pattern('^([^0-9]*)$')]],
      lastName: [lastName, [Validators.required, Validators.pattern('^([^0-9]*)$')]],
    });
  }

  // On Save Form
  onConfirm(): void {
    this.updateTeamleader();
  }

  updateTeamleader(): void {
    const fullName = this.editGroup.value.firstName + ' ' + this.editGroup.value.lastName;

    let index = 0;
    const compareValues: string[] = [
      this.teamleader.name,
    ];
    const values: string[][] = [
      ['name', fullName],
    ];

    const moddedEntries = [];
    for (const value of values) {
      if (value[1] !== compareValues[index]) {
        const key = value[0];
        const entry = value[1];
        moddedEntries.push({[key]: entry});
      }
      index++;
    }

    this.teamleaderService.updateTeamleader(this.teamleader, moddedEntries).subscribe(_ => {
      this.activeModal.close('Saved');
    });
  }

  // Form
  isFieldInvalid(field: string): boolean {
    return (
      (!this.editGroup.get(field).valid && this.editGroup.get(field).touched) ||
      (this.editGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
