<div class="container-fluid">
  <div class="card shadow">
    <div class="card-header py-3">
      <div class="row">
        <div class="col-md-6">
          <div class="text-md-left">
            <p class="text-primary m-0 font-weight-bold" [routerLink]="['/teamleader']">Teamleiter</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-md-right">
            <a (click)="openAddTeamleaderModal()">
              <i class="material-icons">add_circle</i>
            </a>
            <!-- <a data-toggle="modal" data-target="#filterModal">
              <i class="material-icons">filter_alt</i>
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="text-md-left dataTables_filter" id="dataTable_filter">
            <label><input type="search" class="form-control form-control-sm" aria-controls="dataTable" [(ngModel)]="searchTerm" (ngModelChange)="searchTermChanged()" placeholder="Suchen"></label>
          </div>
        </div>
      </div>
      <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
        <table class="table my-0 table-condensed table-sm" id="dataTable">
          <thead>
          <tr>
            <th scope="col" [appSort]="teamleaderArray" [caseComponent]="this" data-order="desc" data-name="name">Name</th>
            <th scope="col">Aktionen</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let teamleader of teamleaderData">
              <td>
                {{teamleader.name}}
              </td>
              <td>
                <a (click)="openShowEmployees(teamleader)">
                  <i class="material-icons">remove_red_eye</i>
                </a>
                <a (click)="openEditTeamleaderModal(teamleader)">
                  <i class="material-icons">mode_edit</i>
                </a>
                <a *ngIf="canEdit | async" (click)="openDeleteModal(teamleader)">
                  <i class="material-icons">delete</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Pagination -->
        <div class="d-flex justify-content-between p-2">
          <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="paginationRefresh()"></ngb-pagination>

          <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="paginationRefresh()">
            <option [ngValue]="10">10 Einträge pro Seite</option>
            <option [ngValue]="15">15 Einträge pro Seite</option>
            <option [ngValue]="20">20 Einträge pro Seite</option>
          </select>
        </div>
        <!-- End pagination -->
      </div>
    </div>
  </div>
</div>
