import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MeasureType} from '../../../Interfaces/measure-type';
import {MeasureService} from '../../../Services/measure/measure.service';

@Component({
  selector: 'app-edit-measure-type-modal',
  templateUrl: './edit-measure-type-modal.component.html',
  styleUrls: ['./edit-measure-type-modal.component.css']
})
export class EditMeasureTypeModalComponent implements OnInit {
  @Input() measureType: MeasureType;

  // Form
  editGroup: FormGroup;
  private formSubmitAttempt: boolean;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,
              private measureService: MeasureService) { }

  ngOnInit(): void {
    const measureTypeName = this.measureType.name;

    this.editGroup = this.fb.group({
      name: [measureTypeName, [Validators.required]],
      hours: [this.measureType.hours, [Validators.required]]
    });
  }

  // On Save Form
  onConfirm(): void {
    this.updateMeasureType();
  }

  updateMeasureType(): void {
    const fullName = this.editGroup.value.name;

    let index = 0;
    const compareValues: string[] = [
      this.measureType.name,
      this.measureType.hours
    ];
    const values: string[][] = [
      ['name', fullName],
      ['hours', this.editGroup.value.hours]
    ];

    const moddedEntries = [];
    for (const value of values) {
      if (value[1] !== compareValues[index]) {
        const key = value[0];
        const entry = value[1];
        moddedEntries.push({[key]: entry});
      }
      index++;
    }

    this.measureService.updateMeasureType(this.measureType, moddedEntries).subscribe(_ => {
      this.activeModal.close('Saved');
    });
  }

  // Form
  isFieldInvalid(field: string): boolean {
    return (
      (!this.editGroup.get(field).valid && this.editGroup.get(field).touched) ||
      (this.editGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
