import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';

import { AppSettings } from '../serviceConfig';
import {FormGroup} from '@angular/forms';
import {Report} from '../../Interfaces/report';
import {Measure} from '../../Interfaces/measure';
import {Upload} from '../../Interfaces/upload';
import {UploadFile} from '../../Interfaces/uploadFile';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private api = AppSettings.API_ENDPOINT;

  constructor(private datePipe: DatePipe, private http: HttpClient) { }

  getReportsForCaseWithName(name: string): Observable<Report[]> {
    const endpoint = this.api + '/functions/reportsWithName';

    return this.http.post<Report[]>(endpoint, {name}).pipe(
      tap(_ => console.log('Recieved cases')),
      map(result => {
        let reports = result['result']; // .filter(report => report.reports[0][1] != null);

        const newArray: string[][] = [];

        for (const innerReport of reports.reports) {
          const date: Date = new Date(innerReport[1]);

          let moddedDate = null;
          if (!isNaN(date.getTime())) {
            moddedDate = this.datePipe.transform(date, 'dd.MM.yyyy');
          }

          // @ts-ignore
          if (innerReport[2] !== undefined) {
            (moddedDate === null) ? newArray.push([innerReport[0], innerReport[1], innerReport[2]]) : newArray.push([innerReport[0], moddedDate, innerReport[2]]);
          } else {
            (moddedDate === null) ? newArray.push([innerReport[0], innerReport[1]]) : newArray.push([innerReport[0], moddedDate]);
          }
        }
        // @ts-ignore
        reports = [
          {
            objectId: reports.objectId,
            reports: newArray
          },
        ];
        return reports;
      })
    );
  }

  deleteReport(report: Report, arrayToUpdate): Observable<Report[]> {
    const endpoint = this.api + '/functions/updateReport/';

    return this.http.post<Report[]>(endpoint, {report, arrayToUpdate}).pipe(
      tap(_ =>
        console.log('Updated deleted entry')),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        // tslint:disable-next-line:no-shadowed-variable
        const empty: Report[] = [];
        return of(empty);
      })
    );
  }

  addReport(form: FormGroup, caseName: string): Observable<Report[]> {
    const endpoint = this.api + '/functions/addReport/';
    const reportsUpdate = [form.value.reportType, form.value.date];

    return this.http.post<Report[]>(endpoint, {caseName, updateArray: reportsUpdate}).pipe(
      tap(_ =>
        console.log('Added case ')),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        // tslint:disable-next-line:no-shadowed-variable
        const empty: Report[] = [];
        return of(empty);
      })
    );
  }

  uploadReport(file, fileName, extension): Observable<UploadFile> {
    const endpoint = this.api + '/files/' + fileName + extension;

    return this.http.post<UploadFile>(endpoint, file).pipe(
      tap(_ => {
        console.log('Uploaded');
      })
    );
  }

  associateUploadWithReport(fileInfo, report, case0, entry): Observable<Upload> {
    // const objectIDToAssociate = report.objectId;
    const endpoint = this.api + '/classes/ReportUpload/';
    const uploadDateSplitted = entry.split('.');
    const uploadDate = new Date(Date.UTC(uploadDateSplitted[2], uploadDateSplitted[1] - 1, uploadDateSplitted[0])).toDateString();

    return this.http.get<Upload>(endpoint).pipe(
      tap(_ => console.log('Getting uploads')),
      mergeMap(uploads => {
        const uploadsArray = uploads.results;

        if (uploadsArray.length === 0) {
          return this.http.post<Upload>(endpoint, {
            case: case0.case,
            reportDate: uploadDate,
            document: {
              name: fileInfo.name,
              url: fileInfo.url,
              __type: 'File'
            }
          }).pipe(
            tap (_ => console.log('Uploaded new one'))
          );
        } else {
          let exists = false;
          let objectID = '';
          let fileName = '';

          for (const upload of uploadsArray) {
            if (upload.reportDate === entry && upload.case === case0.case) {
              exists = true;
              objectID = upload.objectId;
              fileName = upload.document.name;
            }
          }

          if (exists) {
            const newEndpoint = this.api + '/classes/ReportUpload/' + objectID;

            return this.http.put<Upload>(newEndpoint, {
              document: {
                name: fileInfo.name,
                url: fileInfo.url,
                __type: 'File'
              }
            }).pipe(
              tap(_ => console.log('Updated upload')),
              mergeMap(updatedUpload => {
                const deleteEndpoint = this.api + '/files/' + fileName;
                const optionHeaders = new HttpHeaders()
                  .set('X-Parse-Master-Key', 'RqRiJYIdPLaY00pENuwOAdJk3xwWcGCGcLx8IFvH');

                return this.http.delete<Upload>(deleteEndpoint, {headers: optionHeaders}).pipe(
                  tap(_ => console.log('Deleted upload '))
                );
              }));
          } else {
            return this.http.post<Upload>(endpoint, {
              case: case0.case,
              reportDate: uploadDate,
              document: {
                name: fileInfo.name,
                url: fileInfo.url,
                __type: 'File'
              }
            }).pipe(
              tap(_ => console.log('Created new Upload'))
            );
          }
        }
      })
    );
  }

  downloadReport(entry, caseName): Observable<string> {
    const endpoint = this.api + '/functions/downloadReport/';

    const uploadDateSplitted = entry.split('.');
    const uploadDate = new Date(uploadDateSplitted[2] + '-' + uploadDateSplitted[1] + '-' + uploadDateSplitted[0]).toDateString();

    return this.http.post<string>(endpoint, {entry: uploadDate, caseName}).pipe(
      tap(_ => {
      }),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        // tslint:disable-next-line:no-shadowed-variable
        const empty = '';
        return of(empty);
      })
    );
  }

  deleteReportUpload(entry, caseName): Observable<boolean> {
    const endpoint = this.api + '/functions/deleteReportUpload/';

    const uploadDateSplitted = entry.split('.');
    const uploadDate = new Date(uploadDateSplitted[2] + '-' + uploadDateSplitted[1] + '-' + uploadDateSplitted[0]).toDateString();

    return this.http.post<boolean>(endpoint, {entry: uploadDate, caseName}).pipe(
      tap(result => {
        return result['result'];
      }),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        // tslint:disable-next-line:no-shadowed-variable
        const empty = false;
        return of(empty);
      })
    );
  }
}
