import { Component } from '@angular/core';
import { AuthService } from './Services/auth/auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'MeasureAdministration';

  // Access
  loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService) {
    this.checkLoggedIn();
  }

  checkLoggedIn(): void {
    this.authService.isLoggedIn.subscribe(
      resp => {
        this.loggedIn.next(resp);
      }
    );
  }
}
