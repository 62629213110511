import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddEmployeeModalComponent} from '../Models/add-employee-modal/add-employee-modal.component';
import {Employee} from '../../Interfaces/employee';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Role} from '../../Interfaces/role';
import {AuthService} from '../../Services/auth/auth.service';
import {EmployeeService} from '../../Services/employee/employee.service';
import {ShowMeasureEmployeeModalComponent} from '../Models/show-measure-employee-modal/show-measure-employee-modal.component';
import {MeasureService} from '../../Services/measure/measure.service';
import {EditEmployeeModalComponent} from '../Models/edit-employee-modal/edit-employee-modal.component';
import {Router} from '@angular/router';
import {DeleteModalComponent} from '../Models/delete-modal/delete-modal.component';
import {ShowEmployeeHoursModalComponent} from '../Models/show-employee-hours-modal/show-employee-hours-modal.component';
import {TeamleaderService} from '../../Services/teamleader/teamleader.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  // Pagination
  page = 1;
  pageSize = 100;
  collectionSize = 0;

  // Employee Data
  employeeArray: Employee[] = [];
  employeeArrayTmp: Employee[] = [];
  employeeData: Observable<Employee[]> = new Observable<Employee[]>();
  selectedEmployee: Employee;

  // Teamleader
  teamLeaderSelectList = [];

  // Search
  searchTerm = '';
  employeeDataSearch = new BehaviorSubject<boolean>(false);

  // Special Access
  canEdit = new BehaviorSubject<boolean>(false);

  constructor(private modalService: NgbModal, private authService: AuthService,
              private employeeService: EmployeeService, private measureService: MeasureService,
              private teamLeaderService: TeamleaderService, private router: Router) {
    this.checkRole();
    this.getEmployeeList();
    this.getTeamLeaderList();
  }

  ngOnInit(): void {}

  openAddEmployeeModal(): void {
    const modalRef = this.modalService.open(AddEmployeeModalComponent, {size: 'm', centered: true});
    modalRef.componentInstance.teamLeaderSelectList = this.teamLeaderSelectList;
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          this.getEmployeeList();
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openShowMeasures(employeeData: Employee): void {
    this.selectedEmployee = employeeData;

    // this.openMeasureModal();
    const modalRef = this.modalService.open(ShowMeasureEmployeeModalComponent, {size: 'xl', centered: true, scrollable: true});
    modalRef.componentInstance.employee = employeeData;
    // modalRef.componentInstance.measures = of(employeeData.measures);
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          // this.getMeasureList(this.selectedCase.case);
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openShowEmployeeHours(employee: Employee): void {
    this.selectedEmployee = employee;

    // this.openMeasureModal();
    const modalRef = this.modalService.open(ShowEmployeeHoursModalComponent, {size: 'm', centered: true, scrollable: true});
    modalRef.componentInstance.employee = employee;
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          // this.getMeasureList(this.selectedCase.case);
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openEditEmployeeModal(employee: Employee): void {
    const modalRef = this.modalService.open(EditEmployeeModalComponent, {size: 'm', centered: true});
    modalRef.componentInstance.employee = employee;
    modalRef.componentInstance.inputTeamLeaderSelectList = this.teamLeaderSelectList;
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          this.getEmployeeList();
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openDeleteModal(employee: Employee): void {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 's', centered: true});
    modalRef.result.then(
      result => {
        if (result === 'Delete') {
          this.employeeService.deleteEmployee(employee).subscribe(_ => {
            this.getEmployeeList();
          });
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  // Pagination
  public paginationRefresh(): void {
    this.employeeData = of(this.employeeArray.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize));
  }

  public getEmployeeList(): void {
    this.employeeService.getEmployees().subscribe(
      requestsData => {
        this.employeeArray = requestsData['results'];

        this.employeeArray.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        // Store the same into tmp
        this.employeeArrayTmp = this.employeeArray;

        this.collectionSize = this.employeeArray.length;
        this.employeeData = of(this.employeeArray.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize));
      });
  }

  getTeamLeaderList(): void {
    this.teamLeaderService.getTeamleaders().subscribe(teamleaders => {
      const teamleaderArray = teamleaders['results'];

      teamleaderArray.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.teamLeaderSelectList = teamleaderArray;
    });
  }

  // Search Action
  searchTermChanged(): void {
    this.employeeArray = this.employeeArrayTmp;
    this.employeeArray = this.employeeArray.filter(word => word.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    this.collectionSize = this.employeeArray.length;
    this.paginationRefresh();
  }

  // Access
  checkRole(): void {
    this.authService.checkPermission([Role.Superadmin]).subscribe(
      resp => {
        this.canEdit.next(resp['result']);
      }
    );
  }
}
