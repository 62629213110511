<nav class="navbar navbar-expand-lg navbar-light sticky-top" style="background: rgb(71, 183, 244)">
  <a class="navbar-brand">Maßnahmendatenbank</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Aufklappen">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/case']">Fälle</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/employee']">Mitarbeiter</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/teamleader']">Teamleiter</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/measureType']">Maßnahmearten</a>
      </li>
      <li class="nav-item" *ngIf="hasAccess | async">
        <a class="nav-link" [routerLink]="['/registerNewUser']">Neuen Nutzer anlegen</a>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link btn" (click)="logout()">Abmelden</a>
      </li>
    </ul>
  </div>
</nav>
