<!-- AddModal -->
<form [formGroup]="addFormGroup" (ngSubmit)="onSubmit()" class="form-text">
  <div class="modal-header">
    <h5 class="modal-title" id="addModalLabel">Neuen Mitarbeiter hinzufügen</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>Vorname:</label>
    <input type="text" class="form-control" placeholder="Vorname" formControlName="firstName">
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('firstName')">Bitte geben Sie einen korrekten Vornamen ein</small>
    <label>Nachname:</label>
    <input type="text" class="form-control" placeholder="Nachname" formControlName="lastName">
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('lastName')">Bitte geben Sie einen korrekten Nachnamen ein</small>
    <label>Soll-Stunden:</label>
    <select class="form-control" formControlName="hours">
      <option value="">Bitte wählen Sie die Stundenanzahl</option>
      <ng-container *ngFor="let number of arrayThree(41, 5)">
        <option>{{number}}</option>
      </ng-container>
    </select>
    <label>Teamleiter:</label>
    <select class="form-control" formControlName="teamLeader">
      <option value="">Bitte wählen Sie eine Teamleiter</option>
      <ng-container *ngFor="let teamleader of teamLeaderSelectList">
        <option>{{teamleader.name}}</option>
      </ng-container>
    </select>
    <label>Kommentar:</label>
    <input type="text" class="form-control" placeholder="Kommentar" formControlName="comment">
  </div>
  <div class="modal-footer">
    <div style="padding: 40px 20px;margin: 0px;">
      <ngb-alert *ngIf="failingMessage" type="success" (close)="failingMessage=''">{{ failingMessage }}
      </ngb-alert>
      <button class="btn btn-outline-dark" type="button" (click)="activeModal.close('Close click')">Schließen</button>
      <button class="btn btn-outline-primary" type="submit" [disabled]="!addFormGroup.valid">Speichern</button>
    </div>
  </div>
</form>
