<div class="modal-header">
   <h5 class="modal-title" id="modifyModalLabel">Maßnahmen anzeigen/bearbeiten für: <br>
     {{selectedCase.case}}<br>
     <ng-container *ngIf="selectedCase.endDate">
       Beendet zum: {{endDate | date : 'shortDate' : '+0100' : 'de'}}
     </ng-container>
   </h5>
   <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
     <span aria-hidden="true">&times;</span>
   </button>
 </div>
 <div class="modal-body">
   <a (click)="openAddMeasureModal()">
     <i class="material-icons">add_circle</i>
   </a>
   <a (click)="isCollapsed = !isCollapsed">
     <i class="material-icons">remove_red_eye</i>
   </a>
   <a (click)="openUploadModal(selectedCase)">
     <i class="material-icons">upload</i>
   </a>
   <div class="table mt-2 table-editable" id="dataTable" role="grid" aria-describedby="dataTable_info">
     <table class="table my-0 table-condensed table-responsive-xl" id="dataTable">
       <thead>
       <tr>
         <th scope="col">Typ</th>
         <th scope="col">Von</th>
         <th scope="col">Bis</th>
         <!-- <th scope="col">Beendet zum</th> -->
         <th scope="col">Mitarbeiter</th>
         <th scope="col">STD.</th>
         <th scope="col">Kommentar</th>
         <th scope="col">CM</th>
         <th scope="col">SZ</th>
         <th scope="col">Aktionen</th>
       </tr>
       </thead>
       <tbody>
       <tr *ngFor="let measure of measures | async; index as i">
         <td>
           <ng-container *ngIf="measure.type == 'ISE' || measure.type == 'FL' || measure.type == 'BU' || measure.type == 'SA'; else elseBlock">
             <span>{{measure.type + ' ' + measure.variableType}}</span>
           </ng-container>
           <ng-template #elseBlock>
             <span>{{measure.type}}</span>
           </ng-template>
         </td>
         <td>
           <span>{{measure.from}}</span>
         </td>
         <td>
           <span>{{measure.to}}</span>
         </td>
         <!-- <td>
           <span>{{measure.endDate}}</span>
         </td> -->
         <td>
           <ng-container *ngFor="let employee of measure.employee">
            <span>{{employee[0]}}</span><br>
           </ng-container>
         </td>
         <td>
           <ng-container *ngFor="let employee of measure.employee">
             <span>{{employee[1]}}</span><br>
           </ng-container>
         </td>
         <td>
           <span>{{measure.comment}}</span>
         </td>
         <td>
           <span>{{measure.caseManager}}</span>
         </td>
         <td>
           <span>{{measure.socialCentrum}}</span>
         </td>
         <td>
           <a (click)="openEditMeasureModal(measure)">
             <i class="material-icons">edit</i>
           </a>
           <a *ngIf="measure.lastMeasure" (click)="openDeleteModal(measure)">
             <i class="material-icons">delete</i>
           </a>
         </td>
       </tr>
       </tbody>
     </table>
   </div>
   <div #collapse="ngbCollapse" [ngbCollapse]="!isCollapsed">
     <div class="card">
       <div class="card-body">
         <div class="table mt-2 table-editable" id="dataTable" role="grid" aria-describedby="dataTable_info">
           <table class="table table-responsive-xl my-0 table-condensed" id="dataTable">
             <thead>
             <tr>
               <th scope="col">Bericht</th>
               <th scope="col">Datum</th>
               <th scope="col">Aktionen</th>
             </tr>
             </thead>
             <tbody *ngFor="let report of reports | async; index as i">
             <tr *ngFor="let entry of report.reports; index as y">
               <td>
                 <span>{{entry[0]}}</span>
               </td>
               <td>
                 <span>{{entry[1]}}</span>
               </td>
               <td>
                 <div class="row">
                   <div class="col-6 text-left" (click)="fileInput.click()">
                     <input hidden type="file" name="pdf" #fileInput (change)="uploadFilesFromInput($event.target.files, report, entry[1])" accept="application/pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                     <i class="material-icons">file_upload</i>
                   </div>
                   <div class="col-6 text-right">
                     <i class="material-icons" *ngIf="entry[2] != null" (click)="downloadReport(entry[1])">cloud_download</i>
                     <i class="material-icons" *ngIf="entry[2] != null" (click)="deleteReportUpload(entry[1])">delete</i>
                   </div>
                 </div>
               </td>
             </tr>
             </tbody>
           </table>
         </div>
       </div>
     </div>
   </div>
 </div>
 <div class="modal-footer">
   <div id="submit" style="padding: 40px 20px;margin: 0px;">
     <!-- <button class="btn btn-lg btn-primary btn-block" id="submitBtn" type="submit" data-dismiss="modal">Speichern</button> -->
     <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
   </div>
 </div>
