import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDate, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgbCustomDateParserFormatter} from '../../../Directive/dateformat';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MeasureService} from '../../../Services/measure/measure.service';
import {Measure} from '../../../Interfaces/measure';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {EmployeeService} from '../../../Services/employee/employee.service';

@Component({
  selector: 'app-edit-measure-modal',
  templateUrl: './edit-measure-modal.component.html',
  styleUrls: ['./edit-measure-modal.component.css'],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useValue: new NgbCustomDateParserFormatter('DD.MM.YYYY')
    }
  ]
})
export class EditMeasureModalComponent implements OnInit {
  // Inputs
  @Input() public measure: Measure;
  @Input() public measures: Observable<Measure[]>;
  selectEmployees: Observable<string[]> = new Observable<string[]>();
  selectMeasureTypes: Observable<string[]> = new Observable<string[]>();
  measureHours: string[][] = [];
  selectMeasureHours: Observable<number[]> = new Observable<number[]>();
  isVariableMeasureType: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  areHoursValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private formSubmitAttempt: boolean;
  selectVariableTypeHours: number[] = [];

  // Validation
  isEndDateInvalid = new BehaviorSubject<boolean>(false);
  // Validation
  isFromInvalid = new BehaviorSubject<boolean>(false);
  lastToDate = '';
  // endDate = '';

  // Employee and hours
  employees: string[] = [];

  measuresArray = [];

  editMeasureGroup: FormGroup;
  fromModal: NgbDateStruct;
  toModal: NgbDateStruct;
  endDateModal: NgbDateStruct;

  // Dates
  fromDate: NgbDate;
  toDate: NgbDate;
  endDate: string;

  constructor(private fb: FormBuilder, private measureService: MeasureService,
              public activeModal: NgbActiveModal, public formatter: NgbDateParserFormatter,
              private employeeService: EmployeeService) {
    this.setEmployeeList();
    this.setMeasureTypeList();
  }

  ngOnInit(): void {
    this.endDate = this.measure.endDate;

    this.measures.subscribe(measures => {
      this.lastToDate = measures[measures.length - 2].to;
      measures.forEach(measure => {
        this.measuresArray.push(measure);
      });
    });

    this.employees = JSON.parse(JSON.stringify(this.measure.employee));

    const splittedFromDate = this.measure.from.split('.');
    this.fromModal = { day: Number(splittedFromDate[0]), month: Number(splittedFromDate[1]), year: Number(splittedFromDate[2])};
    const splittedToDate = this.measure.to.split('.');
    this.toModal = { day: Number(splittedToDate[0]), month: Number(splittedToDate[1]), year: Number(splittedToDate[2])};
    if (this.measure.endDate !== undefined) {
      const splittedEndDate = this.measure.endDate.split('.');
      this.endDateModal = { day: Number(splittedEndDate[0]), month: Number(splittedEndDate[1]), year: Number(splittedEndDate[2])};
    }

    this.editMeasureGroup = this.fb.group({
      type: [this.measure.type, [Validators.required]],
      variableType: [this.measure.variableType],
      fromModal: [this.fromModal, [Validators.required]],
      toModal: [this.toModal, [Validators.required]],
      hours: [this.measure.employee],
      comment: [this.measure.comment],
      cm: [this.measure.caseManager, [Validators.required]],
      sz: [this.measure.socialCentrum, [Validators.required]]
    });
  }

  // On Save Form
  onConfirm(): void {
    if (this.areHoursValid.value) {
      this.updateMeasure();
    }
  }

  // Form
  addField(): void {
    // @ts-ignore
    this.measure.employee.push(['', 0]); // = tmp;
  }

  setEndDate(date): void {
    if (date === '') {
      this.endDate = '';
    } else {
      if (date.before(this.fromModal) || date.after(this.toModal)) {
        this.isEndDateInvalid.next(true);
      } else {
        this.isEndDateInvalid.next(false);
        this.endDate = this.formatter.format(date);
      }
    }
  }

  // DatePicker
  onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else if (this.fromDate.after(this.toDate) || this.fromDate.equals(this.toDate)) {
      this.isFromInvalid.next(true);
    } else {
      // this.toDate = null;
      this.fromDate = date;
      this.isFromInvalid.next(this.isFieldInvalid('from'));
    }
  }

  removeField(index): void {
    this.measure.employee.splice(index, 1);
  }

  changeEmployee(index, input, internalIndex): void {
    if (this.employees[index] !== undefined) {
      if (this.employees.length !== this.measure.employee.length) {
        if (internalIndex === 1) {
          // @ts-ignore
          this.employees[index].splice(internalIndex, 1, Number(input.target.value));
          // @ts-ignore
          this.measure.employee[index].splice(internalIndex, 1, Number(input.target.value));
        } else {
          // @ts-ignore
          this.employees[index].splice(internalIndex, 1, input.target.value);
          // @ts-ignore
          this.measure.employee[index].splice(internalIndex, 1, input.target.value);
        }
      } else {
        if (internalIndex === 1) {
          // @ts-ignore
          this.employees[index].splice(internalIndex, 1, Number(input.target.value));
        } else {
          // @ts-ignore
          this.employees[index].splice(internalIndex, 1, input.target.value);
        }
      }
    } else {
      if (internalIndex === 0) {
        const inputValue: string = input.target.value;
        // @ts-ignore
        this.employees.push([inputValue, 0]);
      } else {
        const inputValue: string = input.target.value;
        // @ts-ignore
        this.employees.push(['', Number(inputValue)]);
      }
    }

    let tmpHours = 0;
    for (const hoursOfEmployees of this.employees) {
      tmpHours += Number(hoursOfEmployees[1]);
    }
    this.selectMeasureHours.subscribe(result => {
      if (result[result.length - 1] === tmpHours) {
        this.areHoursValid.next(true);
      } /*else if (this.editMeasureGroup.value.type === 'ISE' || this.editMeasureGroup.value.type === 'FL' ||
                 this.editMeasureGroup.value.type === 'Schulassistenz' || this.editMeasureGroup.value.type === 'SFPH II + FL' ||
                 this.editMeasureGroup.value.type === 'EB III + FL') {
        this.areHoursValid.next(true);
      }*/ else {
        this.areHoursValid.next(false);
      }
    });
  }

  setEmployeeList(): void {
    this.employeeService.getEmployees().subscribe(employees => {
      const employeesArray = employees['results'];
      const tmp: string[] = [];

      for (const employee of employeesArray) {
        tmp.push(employee.name);
      }

      tmp.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
      });

      this.selectEmployees = of(tmp);
    });
  }

  setMeasureTypeList(): void {
    this.measureService.getMeasureTypes().subscribe(measureTypes => {
      const measureTypesArray = measureTypes['results'];
      const tmpMeasureTypes: string[] = [];
      const tmpMeasureHours: string[][] = [];

      for (const measureType of measureTypesArray) {
        tmpMeasureTypes.push(measureType.name);

        if (measureType.hours !== undefined) {
          if (measureType.hours.includes('-')) {
            const splittedHours = measureType.hours.split('-');
            for (let i = 0/*Number(splittedHours[0])*/; i <= Number(splittedHours[1]); i += 0.5) {
              tmpMeasureHours.push([measureType.name, i]);
            }
          } else {
            for (let i = 0; i <= Number(measureType.hours); i += 0.5) {
              tmpMeasureHours.push([measureType.name, i]);
            }
          }
        }
      }

      tmpMeasureTypes.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      });

      this.measureHours = tmpMeasureHours;
      this.selectMeasureTypes = of(tmpMeasureTypes);

      const initMeasureType = this.measure.type;
      if (initMeasureType === 'ISE' || initMeasureType === 'BU' || initMeasureType === 'FL' || initMeasureType === 'Schulassistenz' ||
        initMeasureType === 'SFPH II + FL' || initMeasureType === 'EB III + FL') {

        if (initMeasureType === 'ISE') {
          this.selectVariableTypeHours = [];
          for (let i = 0; i <= 19.25; i += 0.25) {
            this.selectVariableTypeHours.push(i);
          }
        } else {
          this.selectVariableTypeHours = [];
          for (let i = 0; i <= 40; i += 0.5) {
            this.selectVariableTypeHours.push(i);
          }
        }
        this.areHoursValid.next(true);
        this.variableTypeHoursChange(this.measure.variableType);
      } else {
        this.measureTypeChange();
      }
    });
  }

  measureTypeChange(): void {
    const measureType = this.editMeasureGroup.value.type;
    const measureHoursSelect = [];

    if (measureType === 'ISE' || measureType === 'BU' || measureType === 'FL' || measureType === 'Schulassistenz' ||
      measureType === 'SFPH II + FL' || measureType === 'EB III + FL') {
      if (measureType === 'ISE') {
        this.selectVariableTypeHours = [];
        for (let i = 0; i <= 19.25; i += 0.25) {
          this.selectVariableTypeHours.push(i);
        }
      } else {
        this.selectVariableTypeHours = [];
        for (let i = 0; i <= 40; i += 0.5) {
          this.selectVariableTypeHours.push(i);
        }
      }

      this.selectMeasureHours = of([0]);
      this.editMeasureGroup.patchValue({variableType: 0});
      this.isVariableMeasureType.next(true);

      this.checkHoursEquality();
      return;
    }

    for (const measureHour of this.measureHours) {
      if (measureType === measureHour[0]) {
        measureHoursSelect.push(measureHour[1]);
      }
    }

    this.selectMeasureHours = of(measureHoursSelect);
    this.editMeasureGroup.patchValue({hours: measureHoursSelect[0]});
    this.isVariableMeasureType.next(false);

    this.checkHoursEquality();
  }

  variableTypeHoursChange(hours): void {
    const measureType = this.editMeasureGroup.value.type;
    const measureHoursSelect = [];

    if (measureType === 'ISE') {
      for (let i = 0; i <= hours; i += 0.25) {
        measureHoursSelect.push(i);
      }
    } else {
      for (let i = 0; i <= hours; i += 0.5) {
        measureHoursSelect.push(i);
      }
    }
    this.selectMeasureHours = of(measureHoursSelect);
    this.isVariableMeasureType.next(true);
    this.checkHoursEquality();
  }

  checkHoursEquality(): void {
    let tmpHours = 0;
    for (const hoursOfEmployees of this.employees) {
      tmpHours += Number(hoursOfEmployees[1]);
    }

    this.selectMeasureHours.subscribe(result => {
      if (result[result.length - 1] === tmpHours) {
        this.areHoursValid.next(true);
      } else {
        this.areHoursValid.next(false);
      }
    });
  }

  updateMeasure(): void {
    if (!this.isEndDateInvalid.value && !this.isFromInvalid.value) {
      const endDateValue = this.endDate !== undefined ? this.endDate : '';

      let index = 0;
      const compareValues: any[] = [
        this.measure.type,
        this.measure.variableType,
        this.measure.from,
        this.measure.to,
        this.measure.endDate,
        this.measure.employee,
        this.measure.hours,
        this.measure.comment,
        this.measure.caseManager,
        this.measure.socialCentrum
      ];
      const values: string[][] = [
        ['type', this.editMeasureGroup.value.type],
        ['variableType', this.editMeasureGroup.value.variableType],
        ['from', this.formatter.format(this.editMeasureGroup.value.fromModal)],
        ['to', this.formatter.format(this.editMeasureGroup.value.toModal)],
        ['endDate', endDateValue !== undefined ? endDateValue : ''],
        ['employee', this.employees],
        ['hours', this.editMeasureGroup.value.hours],
        ['comment', this.editMeasureGroup.value.comment],
        ['caseManager', this.editMeasureGroup.value.cm],
        ['socialCentrum', this.editMeasureGroup.value.sz],
      ];

      const moddedEntries: string[][] = [];
      for (const value of values) {
        if (value[0] === 'employee') {
          if (compareValues[index].length !== value[1].length) {
            moddedEntries.push(['employee', compareValues[index]]);
          } else {
            for (let i = 0; i < value[1].length; i++) {
              if (compareValues[index][i][0] !== value[1][i][0] || compareValues[index][i][1] !== value[1][i][1])  { // Name
                moddedEntries.push(value);
              }
            }
          }
        }

        if (value[1] !== compareValues[index] && value[0] !== 'employee') {
          moddedEntries.push(value);
        }
        index++;
      }

      this.measureService.updateMeasure(this.measure, moddedEntries).subscribe(_ => {
        this.activeModal.close('Saved');
      });
    }
  }

  // FormControlCheck
  isFieldInvalid(field: string): boolean {
    if (field === 'from') {
      const lastDateSplitted: string[] = this.lastToDate.split('.');
      const ngbLastToDate = new NgbDate(Number(lastDateSplitted[2]), Number(lastDateSplitted[1]), Number(lastDateSplitted[0]));

      if (this.fromDate.before(ngbLastToDate) || this.fromDate.equals(ngbLastToDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return (
        (!this.editMeasureGroup.get(field).valid && this.editMeasureGroup.get(field).touched) ||
        (this.editMeasureGroup.get(field).untouched && this.formSubmitAttempt)
      );
    }
  }
}
