import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { User } from '../../Interfaces/user';

import { AppSettings } from '../serviceConfig';
import {debuglog} from 'util';

@Injectable()
export class AuthService {
    private loggedIn = new BehaviorSubject(false);
    private notLoggedIn = new BehaviorSubject(true);
    private api = AppSettings.API_ENDPOINT;

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) { }

  // tslint:disable-next-line:typedef
    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }

  // tslint:disable-next-line:typedef
    get isNotLoggedIn() {
        return this.notLoggedIn.asObservable();
    }

  // tslint:disable-next-line:typedef
  doLogin(form: FormGroup): Observable<User> {
      const endpoint = this.api + '/login';
      console.log('Login');

      return this.http.post<User>(endpoint, {username: form.value.user, password: form.value.pwd}).pipe(
        tap(res => {
          localStorage.setItem( 'sessionToken', res.sessionToken);
          localStorage.setItem( 'name', res.name);
          this.loggedIn.next(true);

          const returnUser: User = {error: undefined, name: '', sessionToken: ''};
          return of(returnUser);
        }),
        catchError(error => {
          const user: User = {error, name: '', sessionToken: ''};
          return of(user);
        })
      );
  }

  checkPermission(roles: string[]): Observable<boolean> {
      const api = this.api + '/functions/access';

      if (this.loggedIn.value) {
        return this.http.post<boolean>(api, {roles}).pipe(
          tap( res => {
            if (res) {
              return of(true);
            } else {
              return of(false);
            }
          }),
          catchError(error => {
            // return this.errorMsg = error.message;
            console.log(error.error.error);
            return of(false);
          })
        );
      } else {
        return of(false);
      }
  }

  checkIfLoggedIn(): Observable<boolean> {
    const api = this.api + '/users/me';

    if (localStorage.getItem('sessionToken') !== null) {
      if (localStorage.getItem('sessionToken') !== '') {
        return this.http.get<boolean>(api).pipe(
          tap( res => {
            /*if (res['error'] !== null) {

            }*/
            this.loggedIn.next(true);
          }),
          catchError(error => {
            // return this.errorMsg = error.message;
            console.log(error.error.error);
            return of(false);
          })
        );
      } else {
        return of(false);
      }
    } else {
      return of(false);
    }
  }

  // tslint:disable-next-line:typedef
    doLogout() {
        const endpoint = this.api + '/logout';

        return this.http.post(endpoint, {}).subscribe(res => {
                this.loggedIn.next(false);
                localStorage.removeItem('sessionToken');
                this.router.navigate(['/']); // Redirect to login window
        });
    }

  // tslint:disable-next-line:typedef
    doRegister(form: FormGroup): Observable<User> {
        const endpoint = this.api + '/users';

        return this.http.post<User>(endpoint, {
            name: form.value.name,
            email: form.value.email,
            username: form.value.username,
            password: form.value.password,
            role: form.value.role === 'Administrator' ? 'administrator' : 'user'
        }).pipe(
          tap(res => {
              console.log('User registered');
              const returnUser: User = {error: undefined, sessionToken: res.sessionToken};
              return returnUser;
          }),
          catchError(error => {
            console.log(error.error.error);
            const errorUser: User = {error, sessionToken: ''};
            return of(errorUser);
          })
        );
    }

    resetPassword(form: FormGroup): Observable<boolean> {
        const endpoint = this.api + '/api/auth/resetPassword';

        return this.http.post(endpoint, {
            email: form.value.email
        }).pipe(
            tap(_ => {
                console.log('Password successfully send!');
            }),
            catchError(this.handleError<any>('resetPassword', []))
        );
    }

    changePassword(form: FormGroup, id: string): Observable<boolean> {
        const endpoint = this.api + '/api/auth/changePassword/';

        return this.http.post(endpoint, {
            pwd: form.value.pwd,
            id,
        }).pipe(
            tap(_ => {
                console.log('Password successfully send!');
            }),
            catchError(this.handleError<any>('changePassword', []))
        );
    }

    /*checkTokenExpiration(): boolean {
        if (!localStorage.getItem('token')) {
            return true;
        } else {
            const date = this.getTokenExpirationDate(localStorage.getItem('token'));

            if (date === undefined) {
                return false;
            }
            return !(date.valueOf() > new Date().valueOf());
        }
    }

    private getTokenExpirationDate(token: string): Date {
        const decodedToken = jwt_decode(token);

        const date = new Date(0);

        date.setUTCSeconds(decodedToken.exp);
        console.log(date);
        return date;
    } */

  // tslint:disable-next-line:typedef
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);

            return of(result as T);
        };
    }
}
