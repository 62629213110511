import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CaseService} from '../../../Services/case/case.service';
import {NgbActiveModal, NgbAlert, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbCustomDateParserFormatter} from '../../../Directive/dateformat';
import {Case} from '../../../Interfaces/case';
import {ShowMeasuresModelComponent} from '../show-measures-model/show-measures-model.component';

@Component({
  selector: 'app-add-case-model',
  templateUrl: './add-case-model.component.html',
  styleUrls: ['./add-case-model.component.css'],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useValue: new NgbCustomDateParserFormatter('DD.MM.YYYY')
    }
  ]
})
export class AddCaseModelComponent implements OnInit {
  private formSubmitAttempt: boolean;

  addFormGroup: FormGroup;
  model: NgbDateStruct;
  date: NgbDate;

  // Error
  failingMessage = '';
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

  constructor(private fb: FormBuilder, private caseService: CaseService,
              public activeModal: NgbActiveModal, public formatter: NgbDateParserFormatter,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.addFormGroup = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required, Validators.pattern('^([^0-9]*)$')]],
      kosi: ['Nein', [Validators.required]],
      modal: [this.model, [Validators.required]],
    });
  }

  // Form submit
  onSubmit(): void {
    const dateModal = this.addFormGroup.value.modal;
    const formattedDate = this.formatter.format(new NgbDate(dateModal.year, dateModal.month, dateModal.day));

    this.caseService.addCase(this.addFormGroup, formattedDate).subscribe(
      result => {
        if (Object.keys(result).length !== 0) {
          this.activeModal.close('Saved');

          const modalRef = this.modalService.open(ShowMeasuresModelComponent, {size: 'xl', centered: true});
          const fullCaseName = this.addFormGroup.value.lastName + ', ' + this.addFormGroup.value.firstName + ' (*' + formattedDate + ')';
          const selectedCase: Case = {
            case: fullCaseName,
            inProgress: '',
            kosi: this.addFormGroup.value.kosi,
            report: '',
            type: ''
          };
          modalRef.componentInstance.selectedCase = selectedCase;
        } else {
          this.failingMessage = 'Eintrag ist bereits vorhanden';
        }
      });
  }

  // FormControlCheck
  isFieldInvalid(field: string): boolean {
    return (
      (!this.addFormGroup.get(field).valid && this.addFormGroup.get(field).touched) ||
      (this.addFormGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
