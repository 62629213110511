import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {DatePipe} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {CaseService} from '../../Services/case/case.service';
import {AuthService} from '../../Services/auth/auth.service';
import {CsvDataService} from '../../Services/csv/csv-data.service';
import {MeasureService} from '../../Services/measure/measure.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Case} from '../../Interfaces/case';
import {Role} from '../../Interfaces/role';
import {Measure} from '../../Interfaces/measure';
import {ShowMeasuresModelComponent} from '../Models/show-measures-model/show-measures-model.component';
import {AddCaseModelComponent} from '../Models/add-case-model/add-case-model.component';
import {EditCaseModalComponent} from '../Models/edit-case-modal/edit-case-modal.component';
import {DeleteModalComponent} from '../Models/delete-modal/delete-modal.component';
import {ReportService} from '../../Services/report/report.service';
import {TeamleaderService} from '../../Services/teamleader/teamleader.service';
import {Teamleader} from '../../Interfaces/teamleader';
import {EmployeeService} from '../../Services/employee/employee.service';
import {Employee} from '../../Interfaces/employee';

@Component({
  selector: 'app-measure',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})
export class CaseComponent implements OnInit {
  // Filter
  employees: Employee[] = [];
  selectTeamLeader: Teamleader[] = [];

  constructor(private datePipe: DatePipe, private caseService: CaseService,
              private csvDataService: CsvDataService, private authService: AuthService,
              private reportService: ReportService, private measureService: MeasureService, private teamleaderService: TeamleaderService,
              private employeeService: EmployeeService,
              private fb: FormBuilder, private router: Router, private modalService: NgbModal) {
    this.checkRole();
    this.employeeService.getEmployees().subscribe(result => {
      this.employees = result['results'];
      console.log('Test');
      this.getCaseList();
    });
  }

  // Pagination
  page = 1;
  pageSize = 100;
  collectionSize = 0;


  // Search
  searchTerm = '';
  caseDataSearch = new BehaviorSubject<boolean>(false);

  // Special Access
  canEdit = new BehaviorSubject<boolean>(false);

  // Cases
  CASESARRAY: Case[] = [];
  CASESARRAYtmp: Case[] = [];
  caseData: Observable<Case[]> = new Observable<Case[]>();
  selectedCase: Case;
  inProgress: string;

  // Form attributes
  editField: string;
  addForm: FormGroup;

  filterForm: FormGroup;
  selectedValueReport = '1';
  selectedValueProgress = '1';
  selectedValueKosi = '1';

  ngOnInit(): void {
    this.addForm = this.fb.group({
      case: [''],
      type: [''],
      kosi: ['']
    });

    this.filterForm = this.fb.group({
      selectReport: [''],
      selectProgress: [''],
      selectKosi: [''],
      selectTeamleader: [0]
    });

    this.teamleaderService.getTeamleaders().subscribe(result => {
      this.selectTeamLeader = result['results'];
    });
  }

  openAddCaseModal(): void {
    const modalRef = this.modalService.open(AddCaseModelComponent, {size: 'm', centered: true});
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          this.CASESARRAY = [];
          this.CASESARRAYtmp = [];
          this.caseData = of([]);
          this.getCaseList();
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openEditCaseModal(selectedCase: Case): void {
    const modalRef = this.modalService.open(EditCaseModalComponent, {size: 'm', centered: true});
    modalRef.componentInstance.case = selectedCase;
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          this.CASESARRAY = [];
          this.CASESARRAYtmp = [];
          this.caseData = of([]);
          this.getCaseList();
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openDeleteModal(case0: Case): void {
    this.reportService.getReportsForCaseWithName(case0.case).subscribe(reports => {
      const modalRef = this.modalService.open(DeleteModalComponent, {size: 's', centered: true});
      // @ts-ignore
      if (reports[0].objectId !== '') {
        modalRef.componentInstance.message = 'Es sind noch Berichte vorhanden';
      }
      modalRef.result.then(
        result => {
          if (result === 'Delete') {
            this.caseService.deleteCase(case0).subscribe(_ => {
              this.CASESARRAY = [];
              this.CASESARRAYtmp = [];
              this.caseData = of([]);
              this.getCaseList();
            });
          }
        },
        reason => {
          console.log('Closed');
        });
    });
  }

  openMeasureModal(): void {
    const modalRef = this.modalService.open(ShowMeasuresModelComponent, {size: 'xl', centered: true});
    modalRef.componentInstance.selectedCase = this.selectedCase;
  }

  public getCaseList(): void {
    this.caseService.getCases().subscribe(
      requestsData => {
        const cases = requestsData['results'];

        this.measureService.getMeasures().subscribe(
          resultData => {
            const measures = resultData['results'];

            for (const case0 of cases) {
              const tmp = measures.filter(measure => measure.case === case0.case);
              const newSortedMeasuresArray = tmp.sort((a, b) => {
                if (a.from < b.from) { return 1; }
                if (a.from > b.from) { return -1; }
              });
              /*if (newSortedMeasuresArray.length > 0) {
                // tslint:disable-next-line:max-line-length
                if (newSortedMeasuresArray[0].endDate === '' || newSortedMeasuresArray[0].endDate === undefined || (new Date(newSortedMeasuresArray[0].endDate)) >= new Date()) {
                  case0.inProgress = 'Ja';
                } else {
                  case0.inProgress = 'Nein';
                }
              } else {
                case0.inProgress = '';
              }*/

              if (case0.endDate === undefined || (new Date(case0.endDate["iso"]).getTime()) > (new Date().getTime())) {
                case0.inProgress = 'Ja';
              } else {
                case0.inProgress = 'Nein';
              }

              let d1 = this.parseDate('00.00.00');
              for (const entry of tmp) {
                const d2 = this.parseDate(entry.from);
                if (d2 > d1) {
                  d1 = d2;
                  if (entry.type === 'ISE' || entry.type === 'FL' || entry.type === 'SA' ||
                    entry.type === 'SFPH II + FL' || entry.type === 'EB III + FL') {
                    case0.type = entry.type + ' ' + entry.variableType;
                  } else {
                    case0.type = entry.type;
                  }
                  case0.to = entry.to;

                  // set Teamleader for running measure
                  for (const employeesNameFromMeasure of entry.employee) {
                    for (const employeesName of this.employees) {
                      if (employeesName.name === employeesNameFromMeasure[0]) {
                        const teamLeaderArray = employeesName.teamLeader;

                        for (const entryOfTeamleaderArray of teamLeaderArray) {
                          case0.teamLeader = [];
                          case0.teamLeader.push(entryOfTeamleaderArray);
                        }
                      }
                    }
                  }

                  const endDate = new Date(entry.endDate);
                  if (!isNaN(endDate.getTime())) {
                    if (endDate.getTime() < Date.now()) {
                      // case0.inProgress = 'Nein';
                    }
                  }
                }
              }
              this.CASESARRAY.push(case0);
            }

            this.CASESARRAY.sort((a, b) => {
              if (a.case < b.case) { return -1; }
              if (a.case > b.case) { return 1; }
              return 0;
            });
            // In progress
            const inProgressArray = this.CASESARRAY.filter(entry => entry.inProgress.toLowerCase() === 'ja');
            this.inProgress = inProgressArray.length.toString();

            // Store the same into tmp
            this.CASESARRAYtmp = this.CASESARRAY;

            this.collectionSize = this.CASESARRAY.length;
            this.caseData = of(this.CASESARRAY.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize));
        });
      }
    );
  }

  parseDate(input): Date {
    const parts = input.match(/(\d+)/g);
    // note parts[1]-1
    if (parts !== null && parts[2] != null) {
      return new Date(parts[2], parts[1] - 1, parts[0]);
    } else {
      return null;
    }
  }

  public paginationRefresh(): void {
    this.caseData = of(this.CASESARRAY.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize));
  }

  checkRole(): void {
    this.authService.checkPermission([Role.Superadmin]).subscribe(
      resp => {
        this.canEdit.next(resp['result']);
      }
    );
  }

  logout(): void {
    this.authService.doLogout();
  }

  // Form
  reload(): void {
    window.location.reload();
  }

  onEdit(caseData: Case): void {
    this.selectedCase = caseData;

    this.openMeasureModal();
  }

  onFilter(): void {
    const form = this.filterForm;
    const report = form.value.selectReport;
    const progress = form.value.selectProgress;
    const kosi = form.value.selectKosi;
    const teamLeader = form.value.selectTeamleader;
    const reportArray = [];
    const progressArray = [];
    const kosiArray = [];
    const teamLeaderArray = [];

    if (report === '1') {
      reportArray.push('', 'ja', 'nein');
    } else if (report === '2') {
      reportArray.push('ja');
    } else {
      reportArray.push('nein');
    }

    if (progress === '1') {
      progressArray.push('ja', 'nein');
    } else if (progress === '2') {
      progressArray.push('ja');
    } else {
      progressArray.push('nein');
    }

    if (kosi === '1') {
      kosiArray.push('', 'ja', 'nein');
    } else if (kosi === '2') {
      kosiArray.push('ja');
    } else {
      kosiArray.push('nein');
    }

    if (teamLeader === '0') {
      for (const teamLeaderFromList of this.selectTeamLeader) {
        teamLeaderArray.push(teamLeaderFromList.name);
      }
    } else {
      teamLeaderArray.push(teamLeader);
    }


    this.CASESARRAY = this.CASESARRAYtmp;
    // tslint:disable-next-line:max-line-length
    this.CASESARRAY = this.CASESARRAY.filter(entry => this.contains(entry.report.toLowerCase(), reportArray) && this.contains(entry.inProgress.toLowerCase(), progressArray) && this.contains(entry.kosi.toLowerCase(), kosiArray) && this.arrayContains(entry.teamLeader !== undefined ? entry.teamLeader : [], teamLeaderArray));
    this.collectionSize = this.CASESARRAY.length;
    this.paginationRefresh();

    // In progress
    const inProgressArray = this.CASESARRAY.filter(entry => entry.inProgress.toLowerCase() === 'ja');
    this.inProgress = inProgressArray.length.toString();

    const modal = document.getElementById('filterModal');
    modal.click();
  }

  contains(target, pattern): boolean{
    let value = 0;
    pattern.forEach((word) => {
      value = value + target.toLowerCase().includes(word);
    });
    return (value > 0);
  }

  arrayContains(target, pattern): boolean {
    let value = false;

    target.forEach((entry) => {
      pattern.forEach((word) => {
        value = entry === word;
      });
    });
    return value;
  }

  // Search Action
  searchTermChanged(): void {
    this.CASESARRAY = this.CASESARRAYtmp;
    this.CASESARRAY = this.CASESARRAY.filter(word => word.case.toLowerCase().includes(this.searchTerm.toLowerCase()));
    this.collectionSize = this.CASESARRAY.length;
    this.paginationRefresh();
  }

  // Helper Functions
  exportList(): void {
    const date = new Date();
    const latestDate = this.datePipe.transform(date, 'dd.MM.yyyy');

    this.csvDataService.exportToCsvTmp('Maßnahmen-' + latestDate, this.CASESARRAY);
  }

  importList(event): void {
    const files = event.target.files;
    const file: File = files[0];

    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');

    reader.onload = (e) => {
      const result: string | ArrayBuffer = reader.result;
      if (typeof result === 'string') {
        const lines = result.split('\n');

        let modLines = [];
        const firstLine = lines[0];
        const keys = firstLine.split(';');

        for (const line of lines) {
          if (line.length > 0) {
            modLines = lines;
          }
        }
        modLines.splice(0, 1);

        const measures: Measure[] = [];
        for (const values of modLines) {
          const splittedTmp = values.split(';');

          if ((splittedTmp[1] === '0') || (splittedTmp[1] === '') || (splittedTmp[1] === '#BEZUG!')) {
            console.log('Not valid format');
          } else if ((splittedTmp[13] !== '0') && (splittedTmp[13] !== '#BEZUG!') && (splittedTmp[13] !== '') && (splittedTmp[13] !== '00.01.00')) {

            const tmpCases = [[2, 10, 8], [13, 14, 15]];

            // tslint:disable-next-line:max-line-length
            if ((splittedTmp[17] !== '0') && (splittedTmp[17] !== '#BEZUG!') && (splittedTmp[17] !== '') && (splittedTmp[17] !== '00.01.00')) {
              tmpCases.push([17, 18, 19]);
            }

            // tslint:disable-next-line:max-line-length
            if ((splittedTmp[21] !== '0') && (splittedTmp[21] !== '#BEZUG!') && (splittedTmp[21] !== '') && (splittedTmp[21] !== '00.01.00')) {
              tmpCases.push([21, 22, 23]);
            }

            for (const field of tmpCases) {
              measures.push({
                employee: splittedTmp[0],
                case: splittedTmp[1],
                type: splittedTmp[field[0]],
                hours: splittedTmp[3],
                reports: (field[0] === 2) ? {
                  reportZero: splittedTmp[4],
                  reportFirst: splittedTmp[5],
                  reportSecond: splittedTmp[6],
                  reportThird: splittedTmp[7],
                } : {},
                from: splittedTmp[field[1]],
                to: splittedTmp[field[2]],
                caseManager: splittedTmp[9],
                with: splittedTmp[11],
                socialCentrum: splittedTmp[12],
                endingMeasure: splittedTmp[25]
              });
            }
          } else {
            measures.push({
              employee: splittedTmp[0],
              case: splittedTmp[1],
              type: splittedTmp[2],
              hours: splittedTmp[3],
              reports: {
                reportZero: splittedTmp[4],
                reportFirst: splittedTmp[5],
                reportSecond: splittedTmp[6],
                reportThird: splittedTmp[7]
              },
              from: splittedTmp[10],
              to: splittedTmp[8],
              caseManager: splittedTmp[9],
              with: splittedTmp[11],
              socialCentrum: splittedTmp[12],
              endingMeasure: splittedTmp[25]
            });
          }
        }

        this.measureService.importMeasures(measures).subscribe(
          res => {
            if (res['result'] === 'imported') {
              window.location.reload();
            }
          }
        );
      }
    };
  }
}
