<div class="row h-50">
  <div class="col-sm-12 h-100 d-table">
    <div class="card card-block d-table-cell align-middle login-clean">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <h2 class="sr-only">Login Form</h2>
        <div class="illustration">
          <ion-icon name="log-in-outline"></ion-icon>
        </div>
        <div class="form-group">
          <input class="form-control" placeholder="Benutzername" formControlName="user"/>
        </div>
        <div class="form-group">
          <input class="form-control" type="password" name="password" placeholder="Passwort" formControlName="pwd"/>
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" type="submit" [disabled]="!loginForm.valid">Anmelden</button>
          <small class="form-text text-danger animated shake" *ngIf="loginError | async">{{ errorMessage }}</small>
        </div>
        <a class="forgot" href="#">Passwort vergessen?</a>
      </form>
    </div>
  </div>
</div>
