import {Component, Input, OnInit} from '@angular/core';
import {Case} from '../../../Interfaces/case';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CaseService} from '../../../Services/case/case.service';
import {Observable, of} from 'rxjs';
import {UploadFile} from '../../../Interfaces/uploadFile';
import {Upload} from '../../../Interfaces/upload';
import {Measure} from '../../../Interfaces/measure';
import {DeleteModalComponent} from '../delete-modal/delete-modal.component';
import {utf8Encode} from '@angular/compiler/src/util';

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.css']
})
export class UploadModalComponent implements OnInit {
  @Input() selectedCase: Case;
  uploads: Observable<Upload[]> = new Observable<Upload[]>();

  constructor(public activeModal: NgbActiveModal, private caseService: CaseService,
              public modalService: NgbModal) { }

  ngOnInit(): void {
    this.getUploads(this.selectedCase.case);
  }

  getUploads(caseName: string): void {
    this.caseService.getUploads(caseName).subscribe(
      uploads => {
        this.uploads = of(uploads);
      });
  }

  download(upload): void {
    this.caseService.download(upload.objectId).subscribe(
      result => {
        const url = result['result'];

        window.open(url, '_blank');
      }
    );
  }

  upload(files: File[]): void {
    // tslint:disable-next-line:prefer-for-o prefer-for-of
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const internalFileName = this.selectedCase.objectId;

      const lastDot = file.name.lastIndexOf('.');
      const fileExtension = file.name.substring(lastDot);

      let fileName = file.name.replace(fileExtension, '');
      fileName = btoa(encodeURIComponent(file.name));
      this.caseService.upload(file, internalFileName, fileExtension).subscribe(
        (fileInfo: UploadFile) => {
          this.caseService.associateUploadWithReport(fileInfo, fileName, this.selectedCase).subscribe(
            upload => {
              this.getUploads(this.selectedCase.case);
            }
          );
        });
    }
  }

  openDeleteModal(upload): void {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 'm', centered: true});
    modalRef.result.then(
      result => {
        if (result === 'Delete') {
          this.deleteUpload(upload);
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  deleteUpload(upload): void {
    this.caseService.deleteUpload(upload.objectId).subscribe(
      result => {
        if (result) {
          this.getUploads(this.selectedCase.case);
        }
      }
    );
  }
}
