import { HttpInterceptor } from '@angular/common/http';
import { HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('outgoing request', req);
        req = req.clone({
            setHeaders: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, PUT, PATCH, POST, DELETE',
              'Access-Control-Allow-Headers': 'Content-Type',
              'X-Parse-Application-Id': 'mOikTt9MgWF9w443woO20ijf7TmOsXaxtR9Wv34L',
              'X-Parse-Session-Token': localStorage.getItem('sessionToken') ? localStorage.getItem('sessionToken') : '',
              'X-Parse-Revocable-Session': '1',
              'Content-Type': 'application/json'
            }
        });
        console.log('new outgoing request', req);

        return next
            .handle(req);
    }
}
