import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../Services/auth/auth.service';
import { Router } from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  private formSubmitAttempt: boolean;

  errorMessage = '';
  loginError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      user: ['', Validators.required],
      pwd: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string): boolean {
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit(): void {
    this.authService.doLogin(this.loginForm).subscribe(
      resp => {
        if (resp.error === undefined) {
          this.loginError.next(false);
          this.router.navigate(['case']).then(() => {
            window.location.reload();
          });
        } else {
          if (resp.error.status === 0) {
            this.errorMessage = 'Serverfehler, bitte an den Administrator wenden';
          } else {
            if (resp.error.error.error === 'Invalid username/password.') {
              this.errorMessage = 'Benutername oder Passwort falsch';
            } else {
              this.errorMessage = resp.error.error.error;
            }
          }
          this.loginError.next(true);
        }
      });
  }

}
