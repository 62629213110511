<form [formGroup]="addFormMeasure" (ngSubmit)="onSubmitAddMeasure()" class="form-text">
  <div class="modal-header">
    <h5 class="modal-title" id="addModalLabel">Neue Maßnahme hinzufügen</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>Maßnahmeart:</label><br>
    <select class="form-control" (change)="measureTypeChange(0)" formControlName="type">
      <option selected></option>
      <ng-container *ngFor="let measureType of measureTypes | async">
        <option [value]="measureType">{{measureType}}</option>
      </ng-container>
    </select>
    <ng-container *ngIf="isVariableMeasureType | async">
      <select class="form-control" (change)="measureTypeChange($event.target.value)" formControlName="variableType">
        <ng-container *ngFor="let number of selectVariableTypeHours">
          <option>{{number}}</option>
        </ng-container>
      </select>
    </ng-container>
    <small class="form-text text-danger animated shake" *ngIf="isFormControlFieldInvalid('type')">Bitte wählen Sie Maßnahme</small>

    <div class="form-group">
      <label>Von - bis:</label>
      <div class="row">
        <div class="input-group col-6">
          <input class="form-control calenderText" (dateSelect)="fromModal = $event; fromDate = $event; onDateSelection($event)" placeholder="TT.MM.JJJJ" [minDate]="{year: 2000, month: 1, day: 1}" #dFrom="ngbDatepicker" ngbDatepicker formControlName="from" required>
          <div class="input-group-append mr-1">
            <button class="btn btn-outline-secondary calenderButton" (click)="dFrom.toggle()" type="button"></button>
          </div>
        </div>
        <div class="input-group col-6">
          <input class="form-control calenderText" (dateSelect)="toModal = $event; toDate = $event; onDateSelection($event)" placeholder="TT.MM.JJJJ" [minDate]="{year: 2000, month: 1, day: 1}" #dTo="ngbDatepicker" ngbDatepicker formControlName="to" required>
          <div class="input-group-append mr-1">
            <button class="btn btn-outline-secondary calenderButton" (click)="dTo.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <small class="form-text text-danger animated shake" *ngIf="isFromInvalid | async">Eine neue Maßnahme kann nicht vor oder am gleichen Tag der bisherigen beginnen</small>


    <label>Mitarbeiter:</label><br>
    <select class="form-control" aria-label="Default select example" formControlName="employee">
      <option selected></option>
      <ng-container *ngFor="let employee of employees | async">
        <option [value]="employee">{{employee}}</option>
      </ng-container>
    </select><br>
    <small class="form-text text-danger animated shake" *ngIf="isFormControlFieldInvalid('employee')">Bitte wählen Sie einen Mitarbeiter</small>

    <label>STD.:</label><br>
    <select class="form-control" formControlName="hours">
      <option value="">Bitte wählen Sie zuerst eine Maßnahme</option>
      <ng-container *ngFor="let measureHour of selectMeasureHours | async">
        <option [value]="measureHour">{{measureHour}}</option>
      </ng-container>
    </select><br>
    <label>Kommentar:</label>
    <input type="text" class="form-control" placeholder="Kommentar" formControlName="comment">
    <label>CM:</label>
    <input type="text" class="form-control" placeholder="CM" formControlName="caseManager">
    <small class="form-text text-danger animated shake" *ngIf="isFormControlFieldInvalid('caseManager')">Bitte geben Sie einen korrekten Wert ein</small>
    <label>SZ:</label>
    <input type="text" class="form-control" placeholder="SZ" formControlName="socialCentrum">
    <small class="form-text text-danger animated shake" *ngIf="isFormControlFieldInvalid('socialCentrum')">Bitte geben Sie ein korrektes Sozialzentrum ein</small>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-primary" [disabled]="!addFormMeasure.valid">Speichern</button>
  </div>
</form>
