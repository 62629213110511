<div class="container-fluid">
  <div class="card shadow">
    <div class="card-header py-3">
      <div class="row">
        <div class="col-md-6">
          <div class="text-md-left">
            <p class="text-primary m-0 font-weight-bold" [routerLink]="['/measureType']">Maßnahmearten</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-md-right">
            <a (click)="openAddMeasureTypeModal()">
              <i class="material-icons">add_circle</i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
        <table class="table my-0 table-condensed table-sm" id="dataTable">
          <thead>
          <tr>
            <th scope="col">Maßnahmenart</th>
            <th scope="col">Maßnahmestunden</th>
            <th scope="col">Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let measureType of measureTypes | async">
            <td>
              {{measureType.name}}
            </td>
            <td>
              {{measureType.hours}}
            </td>
            <td>
              <a (click)="openEditMeasureTypeModal(measureType)">
                <i class="material-icons">mode_edit</i>
              </a>
              <a (click)="openDeleteModal(measureType)">
                <i class="material-icons">delete</i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
