import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgbCustomDateParserFormatter} from '../../../Directive/dateformat';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Case} from '../../../Interfaces/case';
import {CaseService} from '../../../Services/case/case.service';

@Component({
  selector: 'app-edit-case-modal',
  templateUrl: './edit-case-modal.component.html',
  styleUrls: ['./edit-case-modal.component.css'],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useValue: new NgbCustomDateParserFormatter('DD.MM.YYYY')
    }
  ]
})
export class EditCaseModalComponent implements OnInit {
  private formSubmitAttempt: boolean;

  // Inputs
  @Input() public case: Case;

  editCaseGroup: FormGroup;

  birthdayDateModal: NgbDateStruct;
  endDateModal: NgbDateStruct;

  // Dates
  birthDate: NgbDate;
  endDate: NgbDate;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private caseService: CaseService,
              private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    const splittedCase = this.case.case.split(' ');
    const firstName = splittedCase[1];
    const lastNameSplit = splittedCase[0].split(',');
    const lastName = lastNameSplit[0];
    let birthSplit = null;
    if (splittedCase[2]) {
      birthSplit = splittedCase[2];
      birthSplit = birthSplit.replace('(', '');
      birthSplit = birthSplit.replace('*', '');
      birthSplit = birthSplit.replace(')', '');
      birthSplit = birthSplit.split('.');
      this.birthDate = new NgbDate(Number(birthSplit[0]), Number(birthSplit[1]), Number(birthSplit[2]));
      this.birthdayDateModal = { day: Number(birthSplit[0]), month: Number(birthSplit[1]), year: Number(birthSplit[2])};
    }

    if (this.case.endDate !== undefined) {
      const dateToConvert = new Date(this.case.endDate["iso"]);
      dateToConvert.setDate(dateToConvert.getDate() - 1);
      this.endDate = new NgbDate(dateToConvert.getDate(), dateToConvert.getMonth() + 1, dateToConvert.getFullYear());
      this.endDateModal = {day: dateToConvert.getDate(), month: dateToConvert.getMonth() + 1, year: dateToConvert.getFullYear()};
    }

    this.editCaseGroup = this.fb.group({
      firstName: [firstName, [Validators.required, Validators.pattern('^([^0-9]*)$')]],
      lastName: [lastName, [Validators.required, Validators.pattern('^([^0-9]*)$')]],
      birthdayDateModal: [this.birthdayDateModal, [Validators.required]],
      kosi: [this.case.kosi, [Validators.required]],
      endDateModal: [this.endDateModal]
    });
  }

  // On Save Form
  onConfirm(): void {
    this.updateCase();
  }

  updateCase(): void {
    let formattedDate = this.formatter.format(this.birthDate);
    let fullCaseName = '';

    if (formattedDate === '') {
      formattedDate = this.formatter.format(this.birthdayDateModal);
      fullCaseName = this.editCaseGroup.value.lastName + ', ' + this.editCaseGroup.value.firstName + ' (*' + formattedDate + ')';;
    } else {
      fullCaseName = this.editCaseGroup.value.lastName + ', ' + this.editCaseGroup.value.firstName + ' (*' + formattedDate + ')';
    }

    let index = 0;
    const compareValues: any[] = [
      this.case.case,
      this.case.kosi,
      this.case.endDate
    ];
    const values: any[][] = [
      ['case', fullCaseName],
      ['kosi', this.editCaseGroup.value.kosi],
      ['endDate', this.editCaseGroup.value.endDateModal]
    ];

    const moddedEntries: any[][] = [];
    for (const value of values) {
      if (value[1] !== compareValues[index]) {
          moddedEntries.push(value);
      }
      index++;
    }

    this.caseService.updateCase(this.case, moddedEntries).subscribe(_ => {
      this.activeModal.close('Saved');
    });
  }

  // FormControlCheck
  isFieldInvalid(field: string): boolean {
    return (
      (!this.editCaseGroup.get(field).valid && this.editCaseGroup.get(field).touched) ||
      (this.editCaseGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
