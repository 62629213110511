import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {AddMeasureModelComponent} from '../add-measure-model/add-measure-model.component';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Measure} from '../../../Interfaces/measure';
import {MeasureService} from '../../../Services/measure/measure.service';
import {Report} from '../../../Interfaces/report';
import {UploadFile} from '../../../Interfaces/uploadFile';
import {ReportService} from '../../../Services/report/report.service';
import {Case} from '../../../Interfaces/case';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Role} from '../../../Interfaces/role';
import {AuthService} from '../../../Services/auth/auth.service';
import {NgbCustomDateParserFormatter} from '../../../Directive/dateformat';
import {EditMeasureModalComponent} from '../edit-measure-modal/edit-measure-modal.component';
import {UploadModalComponent} from '../upload-modal/upload-modal.component';
import {DeleteModalComponent} from '../delete-modal/delete-modal.component';
import {DatePipe} from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-show-measures-model',
  templateUrl: './show-measures-model.component.html',
  styleUrls: ['./show-measures-model.component.css'],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useValue: new NgbCustomDateParserFormatter('DD.MM.YYYY')
    }
  ]
})
export class ShowMeasuresModelComponent implements OnInit {
  @Input() selectedCase: Case;

  // Case
  endDate: Date;

  // Collapse
  public isCollapsed = false;

  // Special Access
  canEdit = new BehaviorSubject<boolean>(false);

  // Measure
  measures: Observable<Measure[]> = new Observable<Measure[]>();
  editField: string;

  // Report
  reports: Observable<Report[]> = new Observable<Report[]>();
  addFormReport: FormGroup;
  selectedReport: [Report, number];

  // Measure
  selectedMeasure: Measure;
  model: NgbDateStruct;
  date: NgbDate;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal,
              private measureService: MeasureService, private reportService: ReportService,
              private authService: AuthService, private router: Router, private fb: FormBuilder,
              public formatter: NgbDateParserFormatter) {
    this.checkRole();
  }

  ngOnInit(): void {
    this.getMeasureList(this.selectedCase.case);

    if (this.selectedCase.endDate === undefined) {
      this.endDate = undefined;
    } else {
      this.endDate = new Date(this.selectedCase.endDate["iso"]);
    }
    // const pipe = new DatePipe('de-DE');
    // this.endDate = pipe.transform(new Date(this.selectedCase.endDate["iso"]), 'short');

    this.addFormReport = this.fb.group({
      reportType: [''],
      date: [],
    });
  }

  // Permissions
  checkRole(): void {
    this.authService.checkPermission([Role.Superadmin]).subscribe(
      resp => {
        this.canEdit.next(resp['result']);
      }
    );
  }

  // Modal
  openAddMeasureModal(): void {
    const modalRef = this.modalService.open(AddMeasureModelComponent, {size: 'm', centered: true});
    modalRef.componentInstance.selectedCase = this.selectedCase;
    modalRef.componentInstance.measures = this.measures;
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          this.getMeasureList(this.selectedCase.case);
        }
      },
      reason => {
        console.log('Closedsecond');
      });
  }

  openUploadModal(selectedCase: Case): void {
    const modalRef = this.modalService.open(UploadModalComponent, {size: 'm', centered: true});
    modalRef.componentInstance.selectedCase = selectedCase;
    modalRef.result.then(
      result => {
        if (result === 'Closed') {
          console.log('Closed');
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openDeleteModal(measure: Measure): void {
    this.selectedMeasure = measure;

    const modalRef = this.modalService.open(DeleteModalComponent, {size: 'm', centered: true});
    modalRef.result.then(
      result => {
        if (result === 'Delete') {
          this.onMeasureDeleteConfirm();
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openEditMeasureModal(measure: Measure): void {
    const modalRef = this.modalService.open(EditMeasureModalComponent, {size: 'm', centered: true});
    modalRef.componentInstance.measure = measure;
    modalRef.componentInstance.measures = this.measures;
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          setTimeout(() => {
            this.getMeasureList(this.selectedCase.case);
          }, 500);
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  // Images
  uploadFilesFromInput(files: File[], report, entry): void {
    // tslint:disable-next-line:prefer-for-of
    if (entry !== 'entfällt') {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const lastDot = file.name.lastIndexOf('.');
        const fileExtension = file.name.substring(lastDot);

        this.reportService.uploadReport(file, entry, fileExtension).subscribe(
          (fileInfo: UploadFile) => {
            this.reportService.associateUploadWithReport(fileInfo, report, this.selectedCase, entry).subscribe(
              upload => {
                this.getReportList(this.selectedCase.case);
              }
            );
          });
      }
    }
  }

  downloadReport(entry): void {
    const caseName = this.selectedCase.case;

    this.reportService.downloadReport(entry, caseName).subscribe(
      result => {
        const url = result['result'];

        window.open(url, '_blank');
      }
    );
  }

  deleteReportUpload(entry): void {
    const caseName = this.selectedCase.case;

    this.reportService.deleteReportUpload(entry, caseName).subscribe(
      result => {
        if (result) {
          this.getMeasureList(this.selectedCase.case);
        }
      }
    );
  }

  // Measure
  getMeasureList(caseName): void {
    this.measureService.getMeasuresForCaseWithName(caseName).subscribe(measures => {
      this.measures = of(measures);
      this.getReportList(caseName);
    });
  }

  getReportList(caseName: string): void {
    this.reportService.getReportsForCaseWithName(caseName).subscribe(reports => {
      this.reports = of(reports);
    });
  }

  // Report
  onMeasureDeleteConfirm(): void {
    this.measureService.deleteMeasure(this.selectedMeasure).subscribe(
      res => {
        console.log('Deleted');
        this.getMeasureList(this.selectedMeasure.case);
      });
  }

  onReportDeleteConfirm(): void {
    const report = this.selectedReport;
    const index = report[1];
    const reportEntry = report[0];

    const arrayToUpdate: [] = reportEntry.reports;
    arrayToUpdate.splice(index, 1);

    this.reportService.deleteReport(reportEntry, arrayToUpdate).subscribe(
      res => {
        console.log('Deleted');
        this.router.navigate(['case']).then(() => {
          window.location.reload();
        });
      });
  }
}
