<form [formGroup]="editGroup" (ngSubmit)="onConfirm()" class="form-text">
  <div class="modal-header">
    <h5 class="modal-title" id="setEndDateModalTitle">Fall bearbeiten</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>Maßnahmename</label>
    <input type="text" class="form-control" formControlName="name">
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('name')">Bitte geben Sie einen gültigen Namen ein</small>
    <label>Maßnahmestunden</label>
    <input type="text" class="form-control" formControlName="hours">
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('hours')">Bitte geben Sie eine gültige Stundenanzahl (mit Punkt statt Komma) ein</small>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="activeModal.dismiss('Close click')">Schließen</button>
    <button class="btn btn-outline-primary" type="submit" [disabled]="!editGroup.valid">Speichern</button>
  </div>
</form>
