import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../Services/auth/auth.service';
import {BehaviorSubject} from 'rxjs';
import {Role} from '../../Interfaces/role';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  // Access
  hasAccess = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService) {
    this.checkPermission();
  }

  ngOnInit(): void {}

  checkPermission(): void {
    this.authService.checkPermission([Role.Admin, Role.Superadmin]).subscribe(
      resp => {
        this.hasAccess.next(resp['result']);
      }
    );
  }

  logout(): void {
    this.authService.doLogout();
  }
}
