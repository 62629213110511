<div class="modal-header">
  <h5 class="modal-title" id="modifyModalLabel">Stunden</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3>Gesamtstunden: {{ totalHours }}</h3>
  <div class="table mt-2 table-editable" id="dataTable" role="grid" aria-describedby="dataTable_info">
    <table class="table my-0 table-condensed table-responsive-xl" id="dataTable">
      <thead>
      <tr>
        <th scope="col">Stunden</th>
        <th scope="col">Von</th>
        <th scope="col">Bis</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let employeeHour of employeeHours | async; index as i">
        <td>
          <span>{{employeeHour.hours}}</span>
        </td>
        <td>
          <span>{{employeeHour.from}}</span>
        </td>
        <td>
          <span>{{employeeHour.to}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <div id="submit" style="padding: 40px 20px;margin: 0px;">
    <!-- <button class="btn btn-lg btn-primary btn-block" id="submitBtn" type="submit" data-dismiss="modal">Speichern</button> -->
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>
