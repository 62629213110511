<div class="container-fluid">
  <div class="card shadow">
    <div class="card-header py-3">
      <div class="row">
        <div class="col-md-6">
          <div class="text-md-left">
            <p class="text-primary m-0 font-weight-bold" [routerLink]="['/employee']">Mitarbeiter</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-md-right">
            <a (click)="openAddEmployeeModal()">
              <i class="material-icons">add_circle</i>
            </a>
            <!-- <a data-toggle="modal" data-target="#filterModal">
              <i class="material-icons">filter_alt</i>
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="text-md-left dataTables_filter" id="dataTable_filter">
            <label><input type="search" class="form-control form-control-sm" aria-controls="dataTable" [(ngModel)]="searchTerm" (ngModelChange)="searchTermChanged()" placeholder="Suchen"></label>
          </div>
        </div>
      </div>
      <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
        <table class="table my-0 table-condensed table-sm" id="dataTable">
          <thead>
          <tr>
            <th scope="col" [appSort]="employeeArray" [caseComponent]="this" data-order="desc" data-name="name">Name</th>
            <th scope="col" [appSort]="employeeArray" [caseComponent]="this" data-order="desc" data-name="actualHours">Ist</th>
            <th scope="col" [appSort]="employeeArray" [caseComponent]="this" data-order="desc" data-name="hours">Soll</th>
            <th scope="col" [appSort]="employeeArray" [caseComponent]="this" data-order="desc" data-name="capacity">Kapazität</th>
            <th scope="col" [appSort]="employeeArray" [caseComponent]="this" data-order="desc" data-name="teamLeader">Teamleiter</th>
            <th scope="col">Kommentar</th>
            <th scope="col">Aktionen</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let employee of employeeData | async">
              <td>
                {{employee.name}}
              </td>
              <td>
                {{employee.actualHours}}
              </td>
              <td>
                {{employee.hours}}
              </td>
              <td [ngStyle]="{'color':employee.capacity.toString().includes('-') ? 'red' : 'green' }">
                {{employee.capacity}}
              </td>
              <td>
                <ng-container *ngFor="let teamleader of employee.teamLeader">
                  {{teamleader}}<br>
                </ng-container>
              </td>
              <td>{{employee.comment}}</td>
              <td>
                <a (click)="openShowMeasures(employee)">
                  <i class="material-icons">remove_red_eye</i>
                </a>
                <a (click)="openShowEmployeeHours(employee)">
                  <i class="material-icons">hourglass_empty</i>
                </a>
                <a (click)="openEditEmployeeModal(employee)">
                  <i class="material-icons">mode_edit</i>
                </a>
                <a (click)="openDeleteModal(employee)">
                  <i class="material-icons">delete</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Pagination -->
        <div class="d-flex justify-content-between p-2">
          <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="paginationRefresh()"></ngb-pagination>

          <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="paginationRefresh()">
            <option [ngValue]="100">100 Einträge pro Seite</option>
            <option [ngValue]="150">150 Einträge pro Seite</option>
            <option [ngValue]="200">200 Einträge pro Seite</option>
          </select>
        </div>
        <!-- End pagination -->
      </div>
    </div>
  </div>
</div>
