import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { AppSettings } from '../serviceConfig';
import {Observable, of} from 'rxjs';
import {Case} from '../../Interfaces/case';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {UploadFile} from '../../Interfaces/uploadFile';
import {Upload} from '../../Interfaces/upload';

@Injectable({
  providedIn: 'root'
})
export class CaseService {
  private api = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient, private formatter: NgbDateParserFormatter) { }

  getCases(): Observable<Case[]> {
    const endpoint = this.api + '/classes/Case?limit=5000';

    return this.http.get<Case[]>(endpoint).pipe(
      tap(res =>
        console.log('Recieved cases')),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: Case[] = [];
        return of(empty);
      })
    );
  }

  getCaseWithName(caseName: string): Observable<Case> {
    const endpoint = this.api + '/functions/getCaseWithName';

    return this.http.post<Case>(endpoint, {caseName}).pipe(
      tap(res =>
        console.log('Recieved case ' + res['result'])),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: Case = undefined;
        return of(empty);
      })
    );
  }

  addCase(form: FormGroup, formattedDate): Observable<{}> {
    const endpoint = this.api + '/classes/Case';

    const fullCaseName = form.value.lastName + ', ' + form.value.firstName + ' (*' + formattedDate + ')';

    return this.http.post<{}>(endpoint, {case: fullCaseName, report: '', inProgress: 'Ja',
                                                   type: form.value.type, kosi: form.value.kosi}).pipe(
      tap(res =>
        console.log('Added case ' + res['result'])),
      catchError(error => {
        console.log(error.error.error);
        const empty: {} = {};
        return of(empty);
      })
    );
  }

  deleteCase(case0: Case): Observable<Case[]> {
    const endpoint = this.api + '/functions/deleteCase';

    return this.http.post<Case[]>(endpoint, {case: case0}).pipe(
      tap(res =>
        console.log('Deleted case ' + res['result'])),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: Case[] = [];
        return of(empty);
      })
    );
  }

  updateCase(case0, entries): Observable<Case[]> {
    const endpoint = this.api + '/functions/updateCase';
    const objectId = case0.objectId;

    return this.http.post<Case[]>(endpoint, {objectId, case0, entries}).pipe(
      tap(res =>
        console.log('Recieved cases')),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: Case[] = [];
        return of(empty);
      })
    );
  }

  download(objectId): Observable<string> {
    const endpoint = this.api + '/functions/downloadCaseReport/';

    return this.http.post<string>(endpoint, {objectId}).pipe(
      tap(_ => {
      }),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        // tslint:disable-next-line:no-shadowed-variable
        const empty = '';
        return of(empty);
      })
    );
  }

  deleteUpload(objectId): Observable<boolean> {
    const endpoint = this.api + '/functions/deleteCaseReport/';

    return this.http.post<boolean>(endpoint, {objectId}).pipe(
      tap(result => {
        return result['result'];
      }),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        // tslint:disable-next-line:no-shadowed-variable
        const empty = false;
        return of(empty);
      })
    );
  }

  upload(file, fileName, fileExtension): Observable<UploadFile> {
    const endpoint = this.api + '/files/' + fileName + fileExtension;

    return this.http.post<UploadFile>(endpoint, file).pipe(
      tap(_ => {
        console.log('Uploaded');
      })
    );
  }

  associateUploadWithReport(fileInfo, fileName, case0): Observable<Upload> {
    const endpoint = this.api + '/classes/CaseReportUpload/';

    return this.http.post<Upload>(endpoint, {
      case: case0.case,
      fileName,
      document: {
        name: fileInfo.name,
        url: fileInfo.url,
        __type: 'File'
      }
    }).pipe(
      tap(_ => console.log('Uploaded new case report'))
    );
  }

  getUploads(caseName): Observable<Upload[]> {
    const endpoint = this.api + '/functions/getCaseReports/';

    return this.http.post<Upload[]>(endpoint, {caseName}).pipe(
      tap(_ => console.log('Getting uploads')),
      map(uploads => {
        const uploadsArray: Upload[] = [];
        for (const upload of uploads['result']) {
          upload.fileName = decodeURIComponent(atob(upload.fileName));
          uploadsArray.push(upload);
        }
        return uploadsArray;
      })
    );
  }
}
