<div class="modal-header">
  <h5 class="modal-title" id="modifyModalLabel">Hilfspläne hochladen/herunterladen</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div (click)="fileInput.click()">
    <input hidden type="file" name="pdf" #fileInput (change)="upload($event.target.files)" accept="application/pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text" multiple>
    <i class="material-icons">file_upload</i>
  </div>
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Dateiname</th>
      <th scope="col">Aktionen</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let upload of uploads | async">
      <td>
        {{ upload.fileName }}
      </td>
      <td>
        <i class="material-icons" (click)="download(upload)">cloud_download</i>
        <i class="material-icons" (click)="openDeleteModal(upload)">delete</i>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <div id="submit" style="padding: 40px 20px;margin: 0px;">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>
