import { Component, OnInit } from '@angular/core';
import {DeleteModalComponent} from '../Models/delete-modal/delete-modal.component';
import {MeasureType} from '../../Interfaces/measure-type';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MeasureService} from '../../Services/measure/measure.service';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AddMeasureTypeModalComponent} from '../Models/add-measure-type-modal/add-measure-type-modal.component';
import {EditMeasureTypeModalComponent} from '../Models/edit-measure-type-modal/edit-measure-type-modal.component';

@Component({
  selector: 'app-measure-type',
  templateUrl: './measure-type.component.html',
  styleUrls: ['./measure-type.component.css']
})
export class MeasureTypeComponent implements OnInit {
  measureTypes: Observable<MeasureType[]> = new Observable<MeasureType[]>();

  constructor(private modalService: NgbModal, private measureService: MeasureService,
              private router: Router) {
    this.measureService.getMeasureTypes().subscribe(measureTypes => {
      const measureTypesArray = measureTypes['results'];

      measureTypesArray.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });

      this.measureTypes = of(measureTypes['results']);
    });
  }

  ngOnInit(): void {}

  openAddMeasureTypeModal(): void {
    const modalRef = this.modalService.open(AddMeasureTypeModalComponent, {size: 's', centered: true});
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          this.measureService.getMeasureTypes().subscribe(measureTypes => {
            const measureTypesArray = measureTypes['results'];
            this.measureTypes = of(measureTypesArray);
          });
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openEditMeasureTypeModal(measureType: MeasureType): void {
    const modalRef = this.modalService.open(EditMeasureTypeModalComponent, {size: 's', centered: true});
    modalRef.componentInstance.measureType = measureType;
    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          this.measureService.getMeasureTypes().subscribe(measureTypes => {
            this.measureTypes = of(measureTypes['results']);
          });
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  openDeleteModal(measureType: MeasureType): void {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 's', centered: true});
    modalRef.result.then(
      result => {
        if (result === 'Delete') {
          this.measureService.deleteMeasureType(measureType).subscribe(_ => {
            this.measureService.getMeasureTypes().subscribe(measureTypes => {
              this.measureTypes = of(measureTypes['results']);
            });
          });
        }
      },
      reason => {
        console.log('Closed');
      });
  }
}
