import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../Services/auth/auth.service';
import {MeasureService} from '../../../Services/measure/measure.service';
import {NgbCustomDateParserFormatter} from '../../../Directive/dateformat';
import {Case} from '../../../Interfaces/case';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Measure} from '../../../Interfaces/measure';
import {EmployeeService} from '../../../Services/employee/employee.service';

@Component({
  selector: 'app-add-measure-model',
  templateUrl: './add-measure-model.component.html',
  styleUrls: ['./add-measure-model.component.css'],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useValue: new NgbCustomDateParserFormatter('DD.MM.YYYY')
    }
  ]
})
export class AddMeasureModelComponent implements OnInit {
  private formSubmitAttempt: boolean;

  @Input() public selectedCase: Case;
  @Input() public measures: Observable<Measure[]>;
  employees: Observable<string[]> = new Observable<string[]>();
  measureTypes: Observable<string[]> = new Observable<string[]>();
  measureHours: string[][] = [];
  selectMeasureHours: Observable<string[]> = new Observable<string[]>();
  isVariableMeasureType: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectVariableTypeHours: number[] = [];

  addFormMeasure: FormGroup;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  fromModal: NgbDateStruct;
  toDate: NgbDate | null = null;
  toModal: NgbDateStruct;

  // Validation
  isFromInvalid = new BehaviorSubject<boolean>(false);
  lastToDate = '';
  endDate = '';


  constructor(public activeModal: NgbActiveModal, private authService: AuthService,
              private measureService: MeasureService, public formatter: NgbDateParserFormatter,
              private fb: FormBuilder, private calendar: NgbCalendar, private employeeService: EmployeeService) {
    this.setEmployeeList();
    this.setMeasureTypeList();
  }

  ngOnInit(): void {
    this.measures.subscribe(entries => {
      if (entries.length > 0) {
        this.lastToDate = entries[entries.length - 1].to;
      }
    });
    this.setStartDate();
    this.fromModal = { day: this.fromDate.day, month: this.fromDate.month, year: this.fromDate.year };
    this.toModal = { day: this.toDate.day, month: this.toDate.month, year: this.toDate.year };

    this.addFormMeasure = this.fb.group({
      employee: ['', [Validators.required]],
      type: ['', [Validators.required]],
      variableType: ['0', [Validators.required]],
      from: [this.fromModal],
      to: [this.toModal],
      hours: ['', Validators.required],
      comment: [''],
      caseManager: ['', Validators.required],
      socialCentrum: ['', Validators.required],
    });
  }

  // DatePicker
  onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else if (this.fromDate.after(this.toDate) || this.fromDate.equals(this.toDate)) {
      this.isFromInvalid.next(true);
    } else {
      // this.toDate = null;
      this.fromDate = date;
      this.isFromInvalid.next(this.isFieldInvalid('from'));
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  // FormControlCheck
  isFieldInvalid(field: string): boolean {
    if (field === 'from') {
      const lastDateSplitted: string[] = this.lastToDate.split('.');
      const ngbLastToDate = new NgbDate(Number(lastDateSplitted[2]), Number(lastDateSplitted[1]), Number(lastDateSplitted[0]));

      if (this.fromDate.before(ngbLastToDate) || this.fromDate.equals(ngbLastToDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return (
        (!this.addFormMeasure.get(field).valid && this.addFormMeasure.get(field).touched) ||
        (this.addFormMeasure.get(field).untouched)
      );
    }
  }

  isFormControlFieldInvalid(field: string): boolean {
    return (
      (!this.addFormMeasure.get(field).valid && this.addFormMeasure.get(field).touched) ||
      (this.addFormMeasure.get(field).untouched && this.formSubmitAttempt)
    );
  }

  // Service
  setStartDate(): void {
    this.measures.subscribe(entries => {
      if (entries.length > 0) {
        this.endDate = entries[entries.length - 1].endDate;
        const lastDate = entries[entries.length - 1].to;
        const dateSplitted: string[] = lastDate.split('.');

        // tslint:disable-next-line:max-line-length
        this.fromDate = this.calendar.getNext(new NgbDate(Number(dateSplitted[2]), Number(dateSplitted[1]), Number(dateSplitted[0])), 'd', 1);
        this.toDate = this.calendar.getNext(this.fromDate, 'm', 6);
      } else {
        this.fromDate = this.calendar.getToday();
        this.toDate = this.calendar.getNext(this.calendar.getToday(), 'm', 6);
      }
    });
  }

  setEmployeeList(): void {
    this.employeeService.getEmployees().subscribe(employees => {
      const employeesArray = employees['results'];
      const tmp: string[] = [];

      for (const employee of employeesArray) {
        tmp.push(employee.name);
      }

      tmp.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
      });

      this.employees = of(tmp);
    });
  }

  measureTypeChange(hours): void {
    const measureType = this.addFormMeasure.value.type;
    const measureHoursSelect = [];

    for (const measureHour of this.measureHours) {
      if (measureType === measureHour[0]) {
        measureHoursSelect.push(measureHour[1]);
      }
    }

    if (measureType === 'ISE' || measureType === 'BU' || measureType === 'FL' || measureType === 'Schulassistenz' ||
      measureType === 'SFPH II + FL' || measureType === 'EB III + FL') {
      const newHour = hours;

      this.selectMeasureHours = of([newHour]);
      // this.selectMeasureHours = of(measureHoursSelect);
      // this.addFormMeasure.patchValue({hours: measureHoursSelect[0]});
      this.addFormMeasure.patchValue({hours: newHour});

      if (measureType === 'ISE') {
        this.selectVariableTypeHours = [];
        for (let i = 0; i <= 40; i += 0.25) {
          this.selectVariableTypeHours.push(i);
        }
      } else {
        this.selectVariableTypeHours = [];
        for (let i = 0; i <= 40; i += 0.5) {
          this.selectVariableTypeHours.push(i);
        }
      }
    } else {
      const measureHoursSelectTmp = [];
      measureHoursSelectTmp.push(measureHoursSelect[measureHoursSelect.length - 1]);
      this.selectMeasureHours = of(measureHoursSelectTmp);
      this.addFormMeasure.patchValue({hours: measureHoursSelectTmp[0]});
    }

    if (measureType === 'ISE' || measureType === 'BU' || measureType === 'FL' || measureType === 'Schulassistenz' ||
        measureType === 'SFPH II + FL' || measureType === 'EB III + FL') {
      this.isVariableMeasureType.next(true);
    } else {
      this.isVariableMeasureType.next(false);
    }
  }

  setMeasureTypeList(): void {
    this.measureService.getMeasureTypes().subscribe(measureTypes => {
      const measureTypesArray = measureTypes['results'];
      const tmpMeasureTypes: string[] = [];
      const tmpMeasureHours: string[][] = [];

      for (const measureType of measureTypesArray) {
        tmpMeasureTypes.push(measureType.name);

        if (measureType.hours !== undefined) {
          if (measureType.hours.includes('-')) {
            const splittedHours = measureType.hours.split('-');
            for (let i = Number(splittedHours[0]); i <= Number(splittedHours[1]); i += 0.5) {
              tmpMeasureHours.push([measureType.name, i]);
            }
          } else {
            for (let i = 0; i <= Number(measureType.hours); i += 0.5) {
              tmpMeasureHours.push([measureType.name, i]);
            }
          }
        }
      }

      tmpMeasureTypes.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      });

      this.measureHours = tmpMeasureHours;
      this.measureTypes = of(tmpMeasureTypes);
    });
  }

  onSubmitAddMeasure(): void {
    const fromDateModal = this.fromModal;
    const formattedFromDate = new Date(fromDateModal.year, fromDateModal.month - 1, fromDateModal.day).toISOString();
    const toDateModal = this.toModal;
    const formattedToDate = new Date(toDateModal.year, toDateModal.month - 1, toDateModal.day).toISOString();

    if (!this.isFromInvalid.value) {
      this.measureService.addMeasure(this.addFormMeasure, formattedFromDate, formattedToDate, this.selectedCase, this.endDate).subscribe(
        _ => {
          this.activeModal.close('Saved');
        });
    }
  }
}
