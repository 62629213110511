import {Directive, Input, ElementRef, HostListener, Renderer2} from '@angular/core';
import { Sort } from '../Services/util/sort';
import {CaseComponent} from '../Components/case/case.component';

@Directive({
  selector: '[appSort]'
})
export class SortDirective {

  @Input() appSort: Array<any>;
  @Input() caseComponent: CaseComponent;
  constructor(private renderer: Renderer2, private targetElem: ElementRef) { }

  @HostListener('click')
  // tslint:disable-next-line:typedef
  sortData() {
    const sort = new Sort();
    const elem = this.targetElem.nativeElement;
    const order = elem.getAttribute('data-order');
    const type = elem.getAttribute('data-type');
    const property = elem.getAttribute('data-name');

    if (order === 'desc') {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'asc');
      this.caseComponent.paginationRefresh();
    } else {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'desc');
      this.caseComponent.paginationRefresh();
    }
  }

}
