import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.checkPermission(next.data.roles).pipe(
      map(result => {
        if (result['result']) {
          if (next.data.roles) {
            if (result['result']) {
              return true;
            } else {
              this.router.navigate(['']);
              return false;
            }
          } else {
            return true;
          }
        } else {
          this.router.navigate(['']);
          return false;
        }
      })
    );
  }
}
