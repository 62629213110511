<form [formGroup]="editMeasureGroup" (ngSubmit)="onConfirm()" class="form-text">
  <div class="modal-header">
    <h5 class="modal-title" id="setEndDateModalTitle">Maßnahme bearbeiten</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>Maßnahmeart</label><br>
    <select class="form-control" (change)="measureTypeChange()" formControlName="type">
      <option></option>
      <ng-container *ngFor="let measureType of selectMeasureTypes | async">
        <ng-container *ngIf="measureType == measure.type; else elseBlock">
          <option selected [value]="measureType">{{measureType}}</option>
        </ng-container>
        <ng-template #elseBlock>
          <option [value]="measureType">{{measureType}}</option>
        </ng-template>
      </ng-container>
    </select>
    <ng-container *ngIf="isVariableMeasureType | async">
      <select class="form-control" (change)="variableTypeHoursChange($event.target.value)" formControlName="variableType">
        <ng-container *ngFor="let number of selectVariableTypeHours">
          <option [value]="number">{{number}}</option>
        </ng-container>
      </select>
    </ng-container>
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('type')">Bitte wählen Sie eine Maßnahme</small>
    <label>Von - Bis</label>
    <div class="input-group">
      <input class="form-control"
             name="dpFrom" placeholder="TT.MM.JJJJ" formControlName="fromModal" (dateSelect)="fromDate = $event; onDateSelection($event)" [minDate]="{year: 1950, month: 1, day: 1}" ngbDatepicker #dFrom="ngbDatepicker" required>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calenderButton" (click)="dFrom.toggle()" type="button"></button>
      </div>

      <input class="form-control"
             name="dpTo" placeholder="TT.MM.JJJJ" formControlName="toModal" (dateSelect)="toDate = $event; onDateSelection($event)" [minDate]="{year: 1950, month: 1, day: 1}" ngbDatepicker #dTo="ngbDatepicker" required>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calenderButton" (click)="dTo.toggle()" type="button"></button>
      </div>
    </div>
    <small class="form-text text-danger animated shake" *ngIf="isFromInvalid | async">Eine neue Maßnahme kann nicht vor oder am gleichen Tag der bisherigen beginnen</small>
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('fromModal')">Bitte wählen Sie Anfangsdatum</small><br>
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('toModal')">Bitte wählen Sie ein Enddatum</small><br>
    <!-- <label>Beendet zum</label>
    <div class="input-group">
      <input class="form-control"
             name="dpEnd" [value]="this.measure.endDate !== undefined ? this.measure.endDate : null" placeholder="TT.MM.JJJJ" (dateSelect)="setEndDate($event)" (change)="setEndDate($event.target.value)" [minDate]="{year: 2000, month: 1, day: 1}" ngbDatepicker #dEnd="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calenderButton" (click)="dEnd.toggle()" type="button"></button>
      </div>
    </div>
    <small class="form-text text-danger animated shake" *ngIf="isEndDateInvalid | async">Das Enddatum darf nicht vor dem Startdatum liegen</small> -->
    <div class="row">
      <div class="col-md-6 text-left">
        <label>Mitarbeiter</label><br>
        <ng-container *ngFor="let employee of this.measure.employee; let i = index">
          <select class="form-control" (change)="changeEmployee(i, $event, 0)">
            <option value=""></option>
            <ng-container *ngFor="let selectEmployee of selectEmployees | async">
              <ng-container *ngIf="selectEmployee === employee[0]; else elseBlock">
                <option selected [value]="selectEmployee">{{selectEmployee}}</option>
              </ng-container>
              <ng-template #elseBlock>
                <option [value]="selectEmployee">{{selectEmployee}}</option>
              </ng-template>
            </ng-container>
          </select><br>
        </ng-container>
      </div>
      <div class="col-md-6">
        <label>STD.</label><br>
        <ng-container *ngFor="let employee of this.measure.employee; let i = index">
          <select class="form-control" (change)="changeEmployee(i, $event, 1)">
            <ng-container *ngFor="let measureHour of selectMeasureHours | async">
              <ng-container *ngIf="employee[1] == measureHour; else elseBlock">
                <option selected [value]="measureHour">{{measureHour}}</option>
              </ng-container>
              <ng-template #elseBlock>
                <option [value]="measureHour">{{measureHour}}</option>
              </ng-template>
            </ng-container>
          </select>
          <a *ngIf="i != 0" (click)="removeField(i)"><i class="material-icons">delete</i></a><br>
        </ng-container>
      </div>
    </div>
    <a *ngIf="this.measure.employee.length < 3" (click)="addField()">
      <i class="material-icons">add_circle</i>
    </a><br>
    <small class="form-text text-danger animated shake" *ngIf="!(areHoursValid | async)">Die Gesamtstundenzahl stimmt nicht mit der der Maßnahmeart überein oder ist 0</small><br>
    <label>Kommentar</label>
    <input type="text" class="form-control" formControlName="comment">
    <label>CM</label>
    <input type="text" class="form-control" formControlName="cm">
    <label>SZ</label>
    <input type="text" class="form-control" formControlName="sz">
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="activeModal.dismiss('Close click')">Schließen</button>
    <button class="btn btn-outline-primary" type="submit" [disabled]="!editMeasureGroup.valid">Speichern</button>
  </div>
</form>
