import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ShowMeasuresForCaseEmployeeModalComponent} from '../show-measures-for-case-employee-modal/show-measures-for-case-employee-modal.component';
import {MeasureService} from '../../../Services/measure/measure.service';
import {Employee} from '../../../Interfaces/employee';
import {Observable, of} from 'rxjs';
import {Measure} from '../../../Interfaces/measure';
import {Case} from '../../../Interfaces/case';
import {CaseService} from '../../../Services/case/case.service';

@Component({
  selector: 'app-show-measure-employee-modal',
  templateUrl: './show-measure-employee-modal.component.html',
  styleUrls: ['./show-measure-employee-modal.component.css']
})
export class ShowMeasureEmployeeModalComponent implements OnInit {
  @Input() employee: Employee;
  measures: Measure[] = [];
  allMeasures: Measure[] = [];
  cases: Observable<Case[]> = new Observable<Case[]>();
  endedCases: Observable<Case[]> = new Observable<Case[]>();

  constructor(public activeModal: NgbActiveModal, private measureService: MeasureService, private caseService: CaseService,
              private modalService: NgbModal) {}

  ngOnInit(): void {
    this.measureService.getMeasuresForEmployeeWithName(this.employee.name).subscribe(measures => {
      this.measures = measures;
      this.measureService.getMeasures().subscribe(allMeasures => {
        this.allMeasures = allMeasures['results'];
        this.allMeasures = this.sortMeasuresFromAToBAndToDate(this.allMeasures);
        this.measures = this.eliminateDuplicates(this.measures);

        this.seperateCases();
      });
    });
  }

  seperateCases(): void {
    let runningCases = [];
    let endedCases = [];

    this.caseService.getCases().subscribe(cases => {
      for (const measure of this.measures) {
        for (const caseResult of cases["results"]) {
          if (caseResult.case === measure.case) {

            const measuresForCaseWithName = this.allMeasures.filter(a => a.case === measure.case);
            const lastMeasureEntry = measuresForCaseWithName[measuresForCaseWithName.length - 1];
            let isInLastOrPreLastMeasure = false;
            const preLastMeasureEntry = measuresForCaseWithName[measuresForCaseWithName.length - 2];

            lastMeasureEntry.employee.forEach(insideEmployee => {
              if (insideEmployee[0] === this.employee.name) {
                caseResult.lastMeasure = measure;
                isInLastOrPreLastMeasure = true;
              }

              if (preLastMeasureEntry !== undefined) {
                preLastMeasureEntry.employee.forEach(insideEmployee2 => {
                  if (insideEmployee2[0] === this.employee.name) {
                    if (new Date(measure.to).getTime() >= new Date().getTime()) {
                      caseResult.lastMeasure = measure;
                      isInLastOrPreLastMeasure = true;
                    }
                  }
                });
              }

              if (isInLastOrPreLastMeasure) {
                if (caseResult.endDate === undefined || (new Date(caseResult.endDate["iso"]).getTime()) > (new Date().getTime())) {
                  runningCases.push(caseResult);
                } else {
                  endedCases.push(caseResult);
                }
              }
            });

            if (!isInLastOrPreLastMeasure) {
              caseResult.lastMeasure = measure;
              endedCases.push(caseResult);
            }
          }
        }
      }
      runningCases = this.eliminateDuplicates(runningCases);
      endedCases = this.eliminateDuplicates(endedCases);
      this.cases = of(runningCases);
      this.endedCases = of (endedCases);
    });
  }

  eliminateDuplicates(arr): Measure[] {
    return arr.filter((v, i) => arr.findIndex(item => item.case === v.case) === i);
  }

  // Modals
  openShowMeasuresForCase(caseName: string): void {
    const modalRef = this.modalService.open(ShowMeasuresForCaseEmployeeModalComponent, {size: 'xl', centered: true, scrollable: true});
    modalRef.componentInstance.caseName = caseName;

    modalRef.result.then(
      result => {
        if (result === 'Saved') {
          // Something
        }
      },
      reason => {
        console.log('Closed');
      });
  }

  sortMeasuresFromAToBAndToDate(measures: Measure[]): Measure[] {
    const sortedArray = measures;

    // Nach Namen aufsteigend sortieren
    sortedArray.sort((a, b) => {
      const nameA = a.case.toUpperCase();
      const nameB = b.case.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    sortedArray.sort((a, b) => {
      const nameA = a.case.toUpperCase();
      const nameB = b.case.toUpperCase();
      const toA = new Date(a.to).getTime();
      const toB = new Date(b.to).getTime();

      if (nameA === nameB && toA < toB) {
        return -1;
      }
      if (nameA === nameB && toA > toB) {
        return 1;
      }

      return 0;
    });

    return sortedArray;
  }
}
