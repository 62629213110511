import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Employee} from '../../../Interfaces/employee';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EmployeeService} from '../../../Services/employee/employee.service';
import {Teamleader} from '../../../Interfaces/teamleader';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-edit-employee-modal',
  templateUrl: './edit-employee-modal.component.html',
  styleUrls: ['./edit-employee-modal.component.css']
})
export class EditEmployeeModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,
              private employeeService: EmployeeService) {
  }
  // Inputs
  @Input() public employee: Employee;
  @Input() public inputTeamLeaderSelectList: Teamleader[];
  public teamLeaderSelectList: Observable<Teamleader[]> = new Observable<Teamleader[]>();

  editGroup: FormGroup;

  allowedHoursarray(n: number, startFrom: number): number[] {
    const allowedNumbers: number[] = [];
    for (let i = startFrom; i <= n; i += 0.5) {
      allowedNumbers.push(i);
    }
    return allowedNumbers;
  }

  ngOnInit(): void {
    this.teamLeaderSelectList = of(this.inputTeamLeaderSelectList);

    const splittedCase = this.employee.name.split(' ');
    const firstName = splittedCase[0];
    const lastName = splittedCase[1];

    this.editGroup = this.fb.group({
      firstName: [firstName],
      lastName: [lastName],
      hours: [this.employee.hours],
      comment: [this.employee.comment]
    });
  }


 // On Save Form
  onConfirm(): void {
    this.updateEmployee();
  }

  // Form
  addField(): void {
    // @ts-ignore
    this.employee.teamLeader.push(''); // = tmp;
  }

  removeField(index): void {
    this.employee.teamLeader.splice(index, 1);
  }

  changeTeamleader(i, value): void {
    this.employee.teamLeader.splice(i, 1, value);
  }

  updateEmployee(): void {
    const fullName = this.editGroup.value.firstName + ' ' + this.editGroup.value.lastName;

    let index = 0;
    const compareValues: string[] = [
      this.employee.name,
      this.employee.hours,
      this.employee.comment
    ];
    const values: string[][] = [
      ['name', fullName],
      ['hours', this.editGroup.value.hours],
      ['comment', this.editGroup.value.comment]
    ];

    const moddedEntries = [];
    for (const value of values) {
      if (value[1] !== compareValues[index]) {
        const key = value[0];
        const entry = value[1];
        moddedEntries.push({[key]: entry});
      }
      index++;
    }
    moddedEntries.push({['teamLeader']: this.employee.teamLeader});

    this.employeeService.updateEmployee(this.employee, moddedEntries).subscribe(_ => {
      this.activeModal.close('Saved');
    });
  }
}
