import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Components/login/login.component';
import { NavbarComponent } from './Components/navbar/navbar.component';
import { RegisterComponent } from './Components/register/register.component';
import { CaseComponent } from './Components/case/case.component';

// Services
import { CsvDataService } from './Services/csv/csv-data.service';
import { AuthService } from './Services/auth/auth.service';
import { AuthGuard } from './Services/auth/auth.guard';
import { AuthNegateGuard } from './Services/auth/auth-negate.guard';
import { CaseService } from './Services/case/case.service';
import { MeasureService } from './Services/measure/measure.service';
import { ReportService } from './Services/report/report.service';

import { HeaderInterceptor } from './Interceptors/header-interceptor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortDirective } from './Directive/sort.directive';
import { AddMeasureModelComponent } from './Components/Models/add-measure-model/add-measure-model.component';
import { ShowMeasuresModelComponent } from './Components/Models/show-measures-model/show-measures-model.component';
import { AddCaseModelComponent } from './Components/Models/add-case-model/add-case-model.component';
import { EditMeasureModalComponent } from './Components/Models/edit-measure-modal/edit-measure-modal.component';
import { UploadModalComponent } from './Components/Models/upload-modal/upload-modal.component';
import { DeleteModalComponent } from './Components/Models/delete-modal/delete-modal.component';
import { EmployeeComponent } from './Components/employee/employee.component';
import { AddEmployeeModalComponent } from './Components/Models/add-employee-modal/add-employee-modal.component';
import { EditCaseModalComponent } from './Components/Models/edit-case-modal/edit-case-modal.component';
import { ShowMeasureEmployeeModalComponent } from './Components/Models/show-measure-employee-modal/show-measure-employee-modal.component';
import { EditEmployeeModalComponent } from './Components/Models/edit-employee-modal/edit-employee-modal.component';
import { ShowEmployeeHoursModalComponent } from './Components/Models/show-employee-hours-modal/show-employee-hours-modal.component';
import { TeamLeaderComponent } from './Components/team-leader/team-leader.component';
import { EditTeamleaderModalComponent } from './Components/Models/edit-teamleader-modal/edit-teamleader-modal.component';
import { ShowTeamleaderEmployeeModalComponent } from './Components/Models/show-teamleader-employee-modal/show-teamleader-employee-modal.component';
import { AddTeamleaderModalComponent } from './Components/Models/add-teamleader-modal/add-teamleader-modal.component';
import { MeasureTypeComponent } from './Components/measure-type/measure-type.component';
import { AddMeasureTypeModalComponent } from './Components/Models/add-measure-type-modal/add-measure-type-modal.component';
import { EditMeasureTypeModalComponent } from './Components/Models/edit-measure-type-modal/edit-measure-type-modal.component';
import { IonicModule } from '@ionic/angular';
import { ShowMeasuresForCaseEmployeeModalComponent } from './Components/Models/show-measures-for-case-employee-modal/show-measures-for-case-employee-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    RegisterComponent,
    CaseComponent,
    SortDirective,
    AddMeasureModelComponent,
    ShowMeasuresModelComponent,
    AddCaseModelComponent,
    EditMeasureModalComponent,
    UploadModalComponent,
    DeleteModalComponent,
    EmployeeComponent,
    AddEmployeeModalComponent,
    EditCaseModalComponent,
    ShowMeasureEmployeeModalComponent,
    EditEmployeeModalComponent,
    ShowEmployeeHoursModalComponent,
    TeamLeaderComponent,
    EditTeamleaderModalComponent,
    ShowTeamleaderEmployeeModalComponent,
    AddTeamleaderModalComponent,
    MeasureTypeComponent,
    AddMeasureTypeModalComponent,
    EditMeasureTypeModalComponent,
    ShowMeasuresForCaseEmployeeModalComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    IonicModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }, AuthService,
    MeasureService, ReportService,
    AuthGuard, AuthNegateGuard, CsvDataService, CaseService, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
