import { Injectable } from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Case} from '../../Interfaces/case';
import {catchError, map, tap} from 'rxjs/operators';

import { AppSettings } from '../serviceConfig';
import {FormGroup} from '@angular/forms';
import {Report} from '../../Interfaces/report';
import {Measure} from '../../Interfaces/measure';
import {DatePipe} from '@angular/common';
import {MeasureType} from '../../Interfaces/measure-type';
import {Teamleader} from '../../Interfaces/teamleader';

@Injectable({
  providedIn: 'root'
})
export class MeasureService {
  private api = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient, private datePipe: DatePipe) { }

  getMeasures(): Observable<Measure[]> {
    const endpoint = this.api + '/classes/Measure?limit=5000';

    return this.http.get<Measure[]>(endpoint).pipe(
      tap(_ =>
        console.log('Recieved Measures')),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: Measure[] = [];
        return of(empty);
      })
    );
  }

  getLastMeasure(name: string): Observable<Measure> {
    const endpoint = this.api + '/functions/measureLast';

    return this.http.post<Measure>(endpoint, name).pipe(
      tap(_ => console.log('Recieved last measure')),
      map(measure => {
        return measure['result'];
      })
    );
  }

  getMeasuresForCaseWithName(name: string): Observable<Measure[]> {
    const endpoint = this.api + '/functions/measuresWithName';

    return this.http.post<Measure[]>(endpoint, {name}).pipe(
      tap(_ => console.log('Recieved cases')),
      map(result => {
        const measures = result['result'];
        const sortedMeasures = measures.sort((a, b) => {
          const d1 = a.from;
          const d2 = b.from;
          if (d1 < d2) { return -1; }
          if (d1 > d2) { return 1; }
          return 0;
        });

        const tmp: Measure[] = [];
        for (const sortedMeasure of sortedMeasures) {
          const fromDate = this.checkIfDate(sortedMeasure.from);
          const toDate = this.checkIfDate(sortedMeasure.to);
          const endDate = this.checkIfDate(sortedMeasure.endDate);

          const measure: Measure = {
            case: sortedMeasure.case,
            caseManager: sortedMeasure.caseManager,
            employee: sortedMeasure.employee,
            from: fromDate,
            hours: sortedMeasure.employee,
            reports: sortedMeasure.reports,
            comment: sortedMeasure.comment,
            socialCentrum: sortedMeasure.socialCentrum,
            to: toDate,
            endDate,
            type: sortedMeasure.type,
            variableType: sortedMeasure.variableType,
            with: sortedMeasure.with,
            objectId: sortedMeasure.objectId,
            lastMeasure: sortedMeasures[sortedMeasures.length - 1].from === sortedMeasure.from
          };
          tmp.push(measure);
        }
        return tmp;
      })
    );
  }

  getMeasuresForEmployeeWithName(name: string): Observable<Measure[]> {
    const endpoint = this.api + '/functions/measuresWithEmployeeName';

    return this.http.post<Measure[]>(endpoint, {name}).pipe(
      tap(_ => console.log('Recieved measures')),
      map(result => {
        const measures = result['result'];
        const sortedMeasures = measures.sort((a, b) => {
          if (a.case < b.case) { return -1; }
          if (a.case > b.case) { return 1; }
          return 0;
        });

        const tmp: Measure[] = [];
        for (const sortedMeasure of sortedMeasures) {
          const fromDate = this.checkIfDate(sortedMeasure.from);
          const toDate = this.checkIfDate(sortedMeasure.to);
          const endDate = this.checkIfDate(sortedMeasure.endDate);

          const employeeArray = [];
          for (const employee of sortedMeasure.employee) {
            if (employee[0] === name) {
              employeeArray.push(employee);
            }
          }

          const measure: Measure = {
              case: sortedMeasure.case,
              caseManager: sortedMeasure.caseManager,
              employee: employeeArray,
              from: fromDate,
              hours: sortedMeasure.hours,
              reports: sortedMeasure.reports,
              socialCentrum: sortedMeasure.socialCentrum,
              to: toDate,
              endDate,
              type: sortedMeasure.type,
              variableType: sortedMeasure.variableType,
              with: sortedMeasure.with,
              objectId: sortedMeasure.objectId,
              lastMeasure: sortedMeasures[sortedMeasures.length - 1].from === sortedMeasure.from
            };
          tmp.push(measure);
        }
        return tmp;
      })
    );
  }

  checkIfDate(date): string {
    const newDate: Date = new Date(date);

    let returnDate = null;
    if (!isNaN(newDate.getTime())) {
      returnDate = this.datePipe.transform(date, 'dd.MM.yyyy');
    } else {
      returnDate = date;
    }
    return returnDate;
  }

  updateMeasure(measure, entries): Observable<Measure[]> {
    const endpoint = this.api + '/functions/updateMeasure';
    const objectId = measure.objectId;

    return this.http.post<Measure[]>(endpoint, {objectId, measure, entries}).pipe(
      tap(res =>
        console.log('Recieved cases')),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: Measure[] = [];
        return of(empty);
      })
    );
  }

  getRequestsReports(): Observable<Report[]> {
    const endpoint = this.api + '/api/report';

    return this.http.get<Report[]>(endpoint).pipe(
      tap(_ =>
        console.log('Recieved reports')),
      catchError(this.handleError<any>('getRequestsCase', []))
    );
  }

  updateAndModify(form: FormGroup): Observable<boolean> {
    const measureID = form.value.id;
    const endpoint = this.api + `/api/measure/${measureID}`;

    return this.http.patch(endpoint, {
      teamChef: form.value.teamChef,
      employee: form.value.employee,
      caseState: form.value.caseState,
      case: form.value.case,
      caseSuccess: form.value.caseSuccess,
      type: form.value.type,
      hours: form.value.hours,
      tel: form.value.tel,
      cm: form.value.cm,
      email: form.value.email,
      sz: form.value.sz,
      gb: form.value.gb,
      kosi: form.value.kosi
    })
      .pipe(
        tap(_ => console.log('Successfully updated case!')),
        catchError(this.handleError<any>('updateMeasure', []))
      );
  }

  addMeasure(form: FormGroup, fromDate, toDate, selectedCase: Case, endDate): Observable<JSON> {
    const endpoint = this.api + '/functions/addMeasure';
    const caseName = selectedCase.case;

    return this.http.post<JSON>(endpoint, {caseName, type: form.value.type, variableType: form.value.variableType,
                                    employee: form.value.employee, hours: form.value.hours, from: fromDate,
                                    to: toDate, comment: form.value.comment, caseManager: form.value.caseManager,
                                    socialCentrum: form.value.socialCentrum, endDate}).pipe(
      tap(result => console.log(result))
    );
  }

  convertDatestringToDate(date): string {
    const splittedDate = date.split('.');
    let year = splittedDate[2];
    const month = splittedDate[1];
    const day = splittedDate[0];

    if (year.length === 2) {
      year = '20' + year;
    }

    const newDate = new Date('' + year + '-' + month + '-' + day).toISOString();

    return newDate;
  }

  deleteMeasure(measure: Measure): Observable<Measure[]> {
    const endpoint = this.api + '/functions/deleteMeasure';

    return this.http.post<Measure[]>(endpoint, {measure}).pipe(
      tap(res =>
        console.log('Deleted case ' + res['result'])),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: Measure[] = [];
        return of(empty);
      })
    );
  }

  deleteMeasureType(measureType: MeasureType): Observable<{}> {
    const endpoint = this.api + '/classes/MeasureType/' + measureType.objectId;

    return this.http.delete<{}>(endpoint).pipe(
      tap(_ => console.log('Deleted measureType')));
  }

  getMeasureTypes(): Observable<MeasureType[]> {
    const endpoint = this.api + '/classes/MeasureType?limit=5000';

    return this.http.get<MeasureType[]>(endpoint).pipe(
      tap(res =>
        console.log('Recieved measureTypes')),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: MeasureType[] = [];
        return of(empty);
      })
    );
  }

  addMeasureType(formGroup): Observable<MeasureType> {
    const endpoint = this.api + '/classes/MeasureType';

    const name = formGroup.value.name;
    const hours = formGroup.value.hours;

    return this.http.post<MeasureType>(endpoint, {name, hours}).pipe(
      tap(_ => console.log('Added measureType')));
  }

  updateMeasureType(measureType: MeasureType, entries): Observable<JSON> {
    const endpoint = this.api + '/classes/MeasureType/' + measureType.objectId;

    const body = {};
    for (const entry of entries) {
      Object.assign(body, entry);
    }

    return this.http.put<JSON>(endpoint, body).pipe(
      tap(res =>
        console.log('Updated at: '))
    );
  }

  importMeasures(measures: Measure[]): Observable<boolean> {
    const endpoint = this.api + `/functions/importMeasures`;

    return this.http.post(endpoint, { measures }).pipe(
        tap(_ => console.log('Successfully added case!')),
        catchError(this.handleError<any>('addMeasure', []))
      );
  }

  parseDate(input): Date {
    const parts = input.match(/(\d+)/g);
    // note parts[1]-1
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(error);

      return of(result as T);
    };
  }
}
