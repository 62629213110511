import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {TeamleaderService} from '../../../Services/teamleader/teamleader.service';

@Component({
  selector: 'app-add-teamleader-modal',
  templateUrl: './add-teamleader-modal.component.html',
  styleUrls: ['./add-teamleader-modal.component.css']
})
export class AddTeamleaderModalComponent implements OnInit {
  // Error
  failingMessage = '';
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

  // Form
  addFormGroup: FormGroup;
  private formSubmitAttempt: boolean;

  constructor(public activeModal: NgbActiveModal, private teamleaderService: TeamleaderService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.addFormGroup = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^([^0-9]*)$')]],
      lastName: ['', [Validators.required, Validators.pattern('^([^0-9]*)$')]],
    });
  }

  onSubmit(): void {
    this.teamleaderService.addTeamleader(this.addFormGroup).subscribe(
      result => {
        if (Object.keys(result).length !== 0) {
          this.activeModal.close('Saved');
        } else {
          this.failingMessage = 'Eintrag ist bereits vorhanden';
        }
      });
  }

  // Form
  isFieldInvalid(field: string): boolean {
    return (
      (!this.addFormGroup.get(field).valid && this.addFormGroup.get(field).touched) ||
      (this.addFormGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
