<div class="modal-header">
  <h5 class="modal-title" id="deleteModal">Eintrag endgültig löschen</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div id="submit" class="text-center" style="padding: 40px 20px;margin: 0px;">
    <small class="form-text text-danger animated shake" *ngIf="message !== ''">{{ message }}</small><br>
    <button type="button" class="btn btn-danger form-control" (click)="activeModal.close('Delete')">Löschen</button>
    <button type="button" class="btn btn-info form-control" (click)="activeModal.close('Close')">Schließen</button>
  </div>
</div>
