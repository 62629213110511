import {Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {AuthService} from '../../Services/auth/auth.service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  private formSubmitAttempt: boolean;
  registerForm: FormGroup;

  // Select
  roles: string[] = ['Administrator', 'Mitarbeiter'];

  // Error
  passwordError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  serverError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  errorMessage = '';
  successMessage = '';
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) {
    this.registerForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      password: ['', Validators.required],
      pwdRepeat: ['', Validators.required],
      role: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.registerForm.value.password === this.registerForm.value.pwdRepeat) {
      this.authService.doRegister(this.registerForm).subscribe(
        result => {
          if (result.error === undefined) {
            this.passwordError.next(false);
            this.serverError.next(false);
            this.successMessage = 'Benutzer erfolgreich angelegt!';
          } else {
            if (result.error.status === 0) {
              this.errorMessage = 'Serverfehler, bitte an den Administrator wenden';
            } else {
              if (result.error.error.error === 'Account already exists for this email address.') {
                this.errorMessage = 'Benutername existiert bereits für diese E-Mail Adresse';
              } else {
                this.errorMessage = result.error.error.error;
              }
            }
            this.serverError.next(true);
          }
        });
    } else {
      this.passwordError.next(true);
    }
  }

  // FormControlCheck
  isFieldInvalid(field: string): boolean {
    return (
      (!this.registerForm.get(field).valid && this.registerForm.get(field).touched) ||
      (this.registerForm.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
