import { Injectable } from '@angular/core';
import {AppSettings} from '../serviceConfig';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Teamleader} from '../../Interfaces/teamleader';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TeamleaderService {
  private api = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient) { }

  getTeamleaders(): Observable<Teamleader[]> {
    const endpoint = this.api + '/classes/Teamleader?limit=5000';

    return this.http.get<Teamleader[]>(endpoint).pipe(
      tap(res =>
        console.log('Recieved teamleader')),
      catchError(error => {
        // return this.errorMsg = error.message;
        console.log(error.error.error);
        const empty: Teamleader[] = [];
        return of(empty);
      })
    );
  }

  addTeamleader(formGroup): Observable<Teamleader> {
    const endpoint = this.api + '/classes/Teamleader';

    const fullName = formGroup.value.firstName + ' ' + formGroup.value.lastName;

    return this.http.post<Teamleader>(endpoint, {name: fullName}).pipe(
      tap(res => console.log('Recieved teamleader')));
  }

  updateTeamleader(teamleader: Teamleader, entries): Observable<JSON> {
    const endpoint = this.api + '/classes/Teamleader/' + teamleader.objectId;

    const body = {};
    for (const entry of entries) {
      Object.assign(body, entry);
    }

    return this.http.put<JSON>(endpoint, body).pipe(
      tap(res =>
        console.log('Updated at: '))
    );
  }

  deleteTeamleader(teamleader: Teamleader): Observable<{}> {
    const endpoint = this.api + '/classes/Teamleader/' + teamleader.objectId;

    return this.http.delete<{}>(endpoint).pipe(
      tap(res => console.log('Deleted teamleader')));
  }
}
