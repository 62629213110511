<!-- AddModal -->
<form [formGroup]="addFormGroup" (ngSubmit)="onSubmit()" class="form-text">
  <div class="modal-header">
    <h5 class="modal-title" id="addModalLabel">Neuen Fall hinzufügen</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>Vorname:</label>
    <input type="text" class="form-control" placeholder="Vorname" formControlName="firstName" required>
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('firstName')">Bitte geben Sie einen korrekten Vornamen ein</small>
    <label>Nachname:</label>
    <input type="text" class="form-control" placeholder="Nachname" formControlName="lastName" required>
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('lastName')">Bitte geben Sie einen korrekten Nachnamen ein</small>
    <label>Kosi:</label>
    <select class="form-control" formControlName="kosi" required>
      <option value="Ja">Ja</option>
      <option selected value="Nein">Nein</option>
    </select>
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('kosi')">Bitte wählen Sie einen Eintrag</small>
    <label>Geburtsdatum:</label>
    <div class="input-group">
      <input class="form-control" placeholder="TT.MM.JJJJ"
             name="dp" formControlName="modal" (dateSelect)="this.date = $event" [minDate]="{year: 1950, month: 1, day: 1}" ngbDatepicker #d="ngbDatepicker" required>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calenderButton" (click)="d.toggle()" type="button"></button>
      </div>
    </div>
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('modal')">Bitte wählen Sie ein Geburtsdatum</small>
  </div>
  <div class="modal-footer">
    <div style="padding: 40px 20px;margin: 0px;">
      <ngb-alert *ngIf="failingMessage" type="success" (close)="failingMessage=''">{{ failingMessage }}
      </ngb-alert>
      <button class="btn btn-outline-dark" type="button" (click)="activeModal.close('Close click')">Schließen</button>
      <button class="btn btn-outline-primary" type="submit" [disabled]="!addFormGroup.valid">Speichern</button>
    </div>
  </div>
</form>
