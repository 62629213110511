import {Component, Input, OnInit} from '@angular/core';
import {Employee} from '../../../Interfaces/employee';
import {Observable, of} from 'rxjs';
import {MeasureService} from '../../../Services/measure/measure.service';
import {Measure} from '../../../Interfaces/measure';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-measures-for-case-employee-modal-component',
  templateUrl: './show-measures-for-case-employee-modal.component.html',
  styleUrls: ['./show-measures-for-case-employee-modal.component.css']
})
export class ShowMeasuresForCaseEmployeeModalComponent implements OnInit {
  @Input() caseName: string;
  measures: Observable<Measure[]> = new Observable<Measure[]>();

  constructor(private measureService: MeasureService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.measureService.getMeasuresForCaseWithName(this.caseName).subscribe(measures => {
      this.measures = of(measures);
    });
  }

}
