import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MeasureService} from '../../../Services/measure/measure.service';

@Component({
  selector: 'app-add-measure-type-modal',
  templateUrl: './add-measure-type-modal.component.html',
  styleUrls: ['./add-measure-type-modal.component.css']
})
export class AddMeasureTypeModalComponent implements OnInit {
  // Error
  failingMessage = '';
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

  // Form
  addFormGroup: FormGroup;
  private formSubmitAttempt: boolean;

  constructor(public activeModal: NgbActiveModal, private measureService: MeasureService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.addFormGroup = this.fb.group({
      name: ['', [Validators.required]],
      hours: ['', [Validators.required, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]]
    });
  }

  onSubmit(): void {
    this.measureService.addMeasureType(this.addFormGroup).subscribe(
      result => {
        if (Object.keys(result).length !== 0) {
          this.activeModal.close('Saved');
        } else {
          this.failingMessage = 'Eintrag ist bereits vorhanden';
        }
      });
  }

  // Form
  isFieldInvalid(field: string): boolean {
    return (
      (!this.addFormGroup.get(field).valid && this.addFormGroup.get(field).touched) ||
      (this.addFormGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
