<form [formGroup]="editGroup" (ngSubmit)="onConfirm()" class="form-text">
  <div class="modal-header">
    <h5 class="modal-title" id="setEndDateModalTitle">Fall bearbeiten</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>Vorname</label>
    <input type="text" class="form-control" formControlName="firstName">
    <label>Nachname</label>
    <input type="text" class="form-control" formControlName="lastName">
    <label>Soll-Stunden</label>
    <select class="form-control" formControlName="hours">
      <option value="">Bitte wählen Sie die Stundenanzahl</option>
      <ng-container *ngFor="let number of allowedHoursarray(48, 0)">
        <option [value]="number">{{number}}</option>
      </ng-container>
    </select>
    <label>Teamleiter</label>
    <ng-container *ngFor="let teamLeaderField of this.employee.teamLeader; let i = index">
      <select class="form-control" (change)="changeTeamleader(i, $event.target.value)">
        <option value=""></option>
        <ng-container *ngFor="let teamleader of teamLeaderSelectList | async">
          <ng-container *ngIf="teamLeaderField == teamleader.name ; else elseBlock">
            <option selected [value]="teamleader.name">{{teamleader.name}}</option>
          </ng-container>
          <ng-template #elseBlock>
            <option [value]="teamleader.name">{{teamleader.name}}</option>
          </ng-template>
        </ng-container>
      </select>
      <a *ngIf="i != 0" (click)="removeField(i)"><i class="material-icons">delete</i></a><br>
    </ng-container>
    <a *ngIf="this.employee.teamLeader.length < 2" (click)="addField()">
      <i class="material-icons">add_circle</i>
    </a><br>
    <label>Kommentar</label>
    <input type="text" class="form-control" formControlName="comment">
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="activeModal.dismiss('Close click')">Schließen</button>
    <button class="btn btn-outline-primary" type="submit">Speichern</button>
  </div>
</form>
