<!-- AddModal -->
<form [formGroup]="addFormGroup" (ngSubmit)="onSubmit()" class="form-text">
  <div class="modal-header">
    <h5 class="modal-title" id="addModalLabel">Neue Maßnahmeart hinzufügen</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>Maßnahmename:</label>
    <input type="text" class="form-control" placeholder="Maßnahmename" formControlName="name">
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('name')">Bitte geben Sie einen gültigen Namen ein</small>
    <label>Maßnahmenstunden:</label>
    <input type="text" class="form-control" placeholder="Maßnahmename" formControlName="hours">
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('hours')">Bitte geben Sie eine gültige Stundenanzahl (mit Punkt statt Komma) ein</small>
  </div>
  <div class="modal-footer">
    <div style="padding: 40px 20px;margin: 0px;">
      <ngb-alert *ngIf="failingMessage" type="success" (close)="failingMessage=''">{{ failingMessage }}
      </ngb-alert>
      <button class="btn btn-outline-dark" type="button" (click)="activeModal.close('Close click')">Schließen</button>
      <button class="btn btn-outline-primary" type="submit" [disabled]="!addFormGroup.valid">Speichern</button>
    </div>
  </div>
</form>
