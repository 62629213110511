<form [formGroup]="editGroup" (ngSubmit)="onConfirm()" class="form-text">
  <div class="modal-header">
    <h5 class="modal-title" id="setEndDateModalTitle">Fall bearbeiten</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>Vorname</label>
    <input type="text" class="form-control" formControlName="firstName">
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('firstName')">Bitte geben Sie einen korrekten Vornamen ein</small>
    <label>Nachname</label>
    <input type="text" class="form-control" formControlName="lastName">
    <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('lastName')">Bitte geben Sie einen korrekten Nachnamen ein</small>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="activeModal.dismiss('Close click')">Schließen</button>
    <button class="btn btn-outline-primary" type="submit" [disabled]="!editGroup.valid">Speichern</button>
  </div>
</form>
