import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Components
import {LoginComponent} from './Components/login/login.component';
import {RegisterComponent} from './Components/register/register.component';
import {AuthNegateGuard} from './Services/auth/auth-negate.guard';
import {CaseComponent} from './Components/case/case.component';
import {AuthGuard} from './Services/auth/auth.guard';
import {Role} from './Interfaces/role';
import {EmployeeComponent} from './Components/employee/employee.component';
import {TeamLeaderComponent} from './Components/team-leader/team-leader.component';
import {MeasureTypeComponent} from './Components/measure-type/measure-type.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [AuthNegateGuard]
  },
  {
    path: 'case',
    component: CaseComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Admin, Role.Superadmin] }
  },
  {
    path: 'employee',
    component: EmployeeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Admin, Role.Superadmin] }
  },
  {
    path: 'teamleader',
    component: TeamLeaderComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Admin, Role.Superadmin] }
  },
  {
    path: 'measureType',
    component: MeasureTypeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Admin, Role.Superadmin] }
  },
  {
    path: 'registerNewUser',
    component: RegisterComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Superadmin] }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
