<div class="row h-50">
  <div class="col-sm-12 h-100 d-table">
    <div class="card card-block d-table-cell align-middle register-clean">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <h2 class="sr-only">Login Form</h2>
        <div class="illustration">
          <ion-icon name="create-outline"></ion-icon>
        </div>
        <div class="form-group">
          <input class="form-control" placeholder="Vor- und Nachname" formControlName="name"/>
          <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('name')">Bitte geben Sie einen Namen ein</small>
        </div>
        <div class="form-group">
          <input class="form-control" type="email" placeholder="E-Mail" formControlName="email"/>
          <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('email')">Bitte geben Sie eine gültige E-Mail Adresse ein</small>
        </div>
        <div class="form-group">
          <input class="form-control" placeholder="Benutzername" formControlName="username"/>
          <small class="form-text text-danger animated shake" *ngIf="isFieldInvalid('username')">Bitte geben Sie einen Benutzernamen ein</small>
        </div>
        <div class="form-group">
          <input class="form-control" type="password" placeholder="Passwort" formControlName="password"/>
        </div>
        <div class="form-group">
          <input class="form-control" type="password" placeholder="Passwort wiederholen" formControlName="pwdRepeat"/>
        </div>
        <small class="form-text text-danger animated shake" *ngIf="passwordError | async">Die Passwörter stimmen nicht überein</small>
        <div class="form-group">
          <select class="form-control" formControlName="role">
            <option value="">Bitte wählen Sie eine Rolle</option>
            <option *ngFor="let r of roles" [ngValue]="r">{{ r }}</option>
          </select>
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" type="submit" [disabled]="!registerForm.valid">Registrieren</button>
        </div>
        <small class="form-text text-danger animated shake" *ngIf="serverError | async">{{ errorMessage }}</small>
        <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage=''">{{ successMessage }}
        </ngb-alert>
      </form>
    </div>
  </div>
</div>
