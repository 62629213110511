import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import { AuthService} from './auth.service';

@Injectable()
export class AuthNegateGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.checkIfLoggedIn().pipe(
      map (result => {
        if (result) {
          this.router.navigate(['/case']);
        }
        return !result;
      })
    );
  }
}
